import React, { memo } from 'react';
import { useInView } from 'react-intersection-observer';
import { ACTIONS } from '@app/shared/utils/deeplink';
import { FeedbackStates } from '@app/shared/config/feedbackStates';
import { Constants } from '@app/orders/infra/config/Constants';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import type { FC } from 'react';
import type { DependenciesProps, ListOrdersProps } from './ListOrders.type';
import type { ContextType } from '@app/shared/contexts/PublicEnv';
/**
 * Components
 */
import { OrderItem } from '@app/orders/presentation/components/OrderItem';
import { CardSkeleton } from '@app/orders/presentation/components/CardSkeleton';

export const ListOrders: FC<ListOrdersProps> = WithDependencies(
  memo(({ data, virtualizer, hasNextPage, makeFetch, loadingNext, origin, dependencies }) => {
    const { ref } = useInView({
      threshold: 0,
      onChange: (inview) => {
        if (inview && hasNextPage && makeFetch) {
          makeFetch();
        }
      },
    });
    const { redirect, redirectRouter } = (dependencies as DependenciesProps).useLinkContext();
    const { repeatClicked, rateClicked } = (dependencies as DependenciesProps).useTracker();
    const { country } = (dependencies as DependenciesProps).usePublicEnv() as ContextType;
    const query = (dependencies as DependenciesProps).useUrlQuery();
    /**
     * onRepeat
     *
     * @description Function to handle click over "repeat" button in card
     * @param {number} orderId - Order Id
     * @param {number} partnerId - Id partner
     * @param {number} position - Position of card in list
     */
    const onRepeat = (orderId: number, partnerId: number, position: number, vertical: string) => {
      repeatClicked(origin, orderId?.toString(), position);

      return redirect(ACTIONS.BASKET_RECREATION, {
        resourceId: orderId,
        partnerId,
        country: country?.shortName,
      });
    };
    /**
     * onRate
     * TODO
     * @description Function to handle click over "rate" button in card
     * @param {number} orderId - Order Id
     * @param {string} position - Position of card in list
     */
    const onRate = (
      orderId: number,
      position: number,
      surveyId: string,
      resourceId: string,
      countryCode: string = '',
    ) => {
      rateClicked(origin, orderId?.toString(), position);
      redirectRouter(ACTIONS.NEW_FEEDBACK, {
        resourceId,
        surveyId,
        country: countryCode ? countryCode.toLowerCase() : '',
        hasBottomNav: query.get(Constants.BOTTOM_NAV_QUERY_PARAM) || false,
      });
    };

    /**
     * redirectToDetail
     *
     * @description function to redirect to order detail
     * @param {number} orderId - Order Id
     */
    const redirectToDetail = (orderId: number) => {
      redirect(ACTIONS.DETAIL, { orderId });
    };

    return (
      <div>
        {virtualizer.map((virtualRow) => {
          const isLoaderRow = virtualRow.index > data.length - 1;
          const order = data[virtualRow.index];
          return (
            <div
              key={virtualRow.index}
              ref={virtualRow.measureElement}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              <div style={{ padding: '0px 16px' }}>
                {isLoaderRow ? (
                  <div data-testid="loader" ref={ref}>
                    {hasNextPage && loadingNext ? <CardSkeleton /> : null}
                  </div>
                ) : (
                  <OrderItem
                    key={order?.orderId}
                    orderId={order?.orderId}
                    vendor={order?.vendor?.name}
                    vendorImage={order?.vendor?.logo}
                    items={order?.items}
                    status={order?.status}
                    registeredDate={order?.registeredDate}
                    orderAmount={order?.orderAmount}
                    currency={order?.currency}
                    country={order?.countryCode ? order.countryCode.toLowerCase() : ''}
                    rateEnabled={order?.feedback?.state === FeedbackStates.AVAILABLE}
                    ratePoint={order?.feedback?.rate}
                    vendorState={order?.vendor?.state}
                    openTime={order?.vendor?.openTime}
                    businessType={order?.vendor.businessType}
                    showHelpCenter={true}
                    onRepeat={() => {
                      onRepeat(
                        order?.orderId,
                        order?.vendor?.vendorId,
                        virtualRow.index + 1,
                        order?.vendor?.businessType,
                      );
                    }}
                    onRate={() =>
                      onRate(
                        order?.orderId,
                        virtualRow.index + 1,
                        order?.feedback?.surveyId,
                        order?.feedback?.resourceId,
                        order?.countryCode,
                      )
                    }
                    onClickCard={() => redirectToDetail(order?.orderId)}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }),
);
