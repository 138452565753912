import React from 'react';
import styled from 'styled-components';

type Props = {
  pTop?: string;
  pBottom?: string;
  pLeft?: string;
  pRight?: string;
  mTop?: string;
  height?: string | number;
  ref?: any;
};

export const Container = styled.div<Props>`
  padding-top: ${(props) => (props.pTop ? `${props.pTop}px` : '16px')};
  padding-bottom: ${(props) => (props.pBottom ? `${props.pBottom}px` : '30px')};
  padding-left: ${(props) => (props.pLeft ? `${props.pLeft}px` : '16px')};
  padding-right: ${(props) => (props.pRight ? `${props.pRight}px` : '16px')};
  height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
  margin-top: ${(props) => (props.mTop ? `${props.mTop}px` : '0px')};
  position: relative;
`;
