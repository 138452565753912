import styled, { createGlobalStyle } from 'styled-components';

export const LocalStyle = createGlobalStyle`
  body, html {
    background-color: #FFF;
    scroll-behavior: smooth;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  * {
    font-family: 'Segma' !important;
    letter-spacing: 0;
    margin: 0;
    padding: 0;
  }
`;
