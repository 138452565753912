import { useMemo } from 'react';
import { getWindow } from '@app/shared/utils/window';
import parseUserAgent from '@appscore/device-detector';

function useIsWebView() {
  const isCustomWebView = useMemo(() => {
    const windowObject = getWindow();
    if (!windowObject) return false;
    const deviceInfo = parseUserAgent(windowObject.navigator.userAgent);
    const { deviceType } = deviceInfo;
    return deviceType === 'WEBVIEW';
  }, []);
  return isCustomWebView;
}
export default useIsWebView;
