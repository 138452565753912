import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ ref?: any }>`
  padding: 0px 16px 0px 16px;
`;

const TitleSection = styled.div<{ bBottom?: boolean }>`
  margin-top: ${(props) => props.theme.space('space-08')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${(props) => (props.bBottom ? '1px solid #C3C0C8' : '0px')};
  padding-bottom: ${(props) => (props.bBottom ? '16px' : '0px')};
  margin-bottom: ${(props) => (props.bBottom ? '16px' : '0px')};
`;

const SwipperSection = styled.div<{
  fullWidth?: boolean;
  ref?: any;
  height?: number;
  width?: number;
}>`
  display: ${(props) => (props.fullWidth ? 'block' : 'flex')};
  justify-content: space-between;
  flex-shrink: 0;
  gap: 12px;
  margin-top: ${(props) => props.theme.space('space-08')};
  overflow-y: hidden;
  margin-bottom: ${(props) => props.theme.space('space-08')};
  width: auto;
  height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
`;

const SwiperOutsideContainer = styled.div<{ display?: boolean; fullWidth?: boolean }>`
  margin-left: ${(props) => `-${props.theme.space('space-08')}`};
  margin-right: ${(props) => `-${props.theme.space('space-08')}`};
  display: ${(props) => (props?.display ? 'block' : 'none')};
  & > div {
    display: ${(props) => (props?.fullWidth ? 'block' : 'flex')};
    & > div {
      display: ${(props) => (props?.fullWidth ? 'block' : 'flex')};
    }
  }
`;

const SwiperElement = styled.div<{ marginLeft?: string; marginRight?: string }>`
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0px')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0px')};
`;

export { Container, TitleSection, SwipperSection, SwiperOutsideContainer, SwiperElement };
