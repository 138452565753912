import React from 'react';
import { UserCardMenu } from '@pedidosya/web-bottom-nav';
import { usePublicEnv, ContextType } from '@app/shared/contexts/PublicEnv';

export const LoadableMenu = () => {
  const { environment, country, user } = usePublicEnv() as ContextType;
  const usr = user
    ? {
        avatar: user?.avatar,
        name: user?.name,
        lastName: user?.lastName,
        nickname: user?.nickname,
        email: user?.email,
      }
    : null;

  return (
    <div>
      <UserCardMenu env={environment} countryShortName={country?.shortName} user={usr} />
    </div>
  );
};
