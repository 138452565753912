import React from 'react';
import styled from 'styled-components';

const InsideContainer = styled.div`
  padding: ${(props) => props.theme.space('space-08')};
  display: flex;
  justify-content: space-between;
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  self-align: flex-start;
  align-items: center;
  & > span {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    height: 24px;
    cursor: pointer;
    margin-right: ${(props) => props.theme.space('space-08')};
  }
  & > h3 {
    display: inline-block;
    vertical-align: middle;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const RightSide = styled.div`
  align-items: center;
  self-align: flex-end;
`;

export { InsideContainer, LeftSide, RightSide };
