import React, { ForwardedRef } from 'react';
import styled from 'styled-components';

const NavContainer = styled.nav<{ ref?: any }>`
  height: 56px;
  margin-bottom: ${(props) => props.theme.space('space-08')};
`;

const MockContainer = styled.div`
  position: absolute;
  top: ${(props) => props.theme.space('space-13')};
  right: 80px;
`;

export { NavContainer, MockContainer };
