import React from 'react';
import { Switch, Route } from 'react-router-dom';
/**
 * config
 */
import { Routes } from '@app/orders/infra/config/Routes';
/**
 * Pages
 */
import { ListOrders } from '@app/orders/presentation/pages/ListOrders';

export const RouterApp = () => {
  return (
    <Switch>
      <Route exact path={Routes.MY_ORDERS}>
        <ListOrders />
      </Route>
    </Switch>
  );
};
