import React, { memo } from 'react';
import PropTypes from 'prop-types';
import OrderBag from '@app/assets/images/order_bag.svg';
import OrderFilterBag from '@app/assets/images/order_filter_bag.svg';
import OrderBagSmall from '@app/assets/images/order_bag_small.svg';
import FirstOrder from '@app/assets/images/first_order.svg';
import Button from '@pedidosya/web-fenix/atoms/Button';
import Image from '@pedidosya/web-fenix/foundations/Image';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import type { FC } from 'react';
import type { Props } from './EmptyState.type';
/**
 * Styled
 */
import {
  Container,
  TitleContainer,
  DescriptionContainer,
  ImageContainer,
  CTAContainer,
} from './EmptyState.style';

export const EmptyState: FC<Props> = memo(
  ({ title, description, buttonText, buttonAction, titleStyle = 'big', bagType = 'bag' }) => {
    const bags = {
      bag: OrderBag,
      filter: OrderFilterBag,
      bag_small: OrderBagSmall,
      bag_first: FirstOrder,
    } as { [key: string]: any };

    return (
      <Container>
        <ImageContainer>
          <Image src={bags[bagType]} alt="Order bag" width={titleStyle === 'big' ? 128 : 100} />
        </ImageContainer>
        <TitleContainer titleStyle={titleStyle}>
          <Typography token="font-subtitle-highcontrast-sentence-large">{title}</Typography>
        </TitleContainer>
        <DescriptionContainer>
          <Typography token="font-body-midcontrast-sentence-small">{description}</Typography>
        </DescriptionContainer>
        {buttonText && (
          <CTAContainer>
            <Button
              type="button"
              label={buttonText}
              hierarchy="primary"
              size="small"
              onClick={buttonAction}
            />
          </CTAContainer>
        )}
      </Container>
    );
  },
);
