import React from 'react';
import { Actions as ReduceActions } from '@app/orders/data/contexts/OrdersProvider/actions';
import type { Controller } from '@app/shared/types/base.controller';
import type { DependenciesProps } from './FiltersAdvanced.type';
import type { FilterState } from '@app/orders/domain/models';

type FilterController = Controller<
  {
    filtersDate: Array<FilterState>;
    filtersStates: Array<FilterState>;
    onSelectState(value: string): void;
    onSelectPeriod(value: string): void;
  },
  {},
  DependenciesProps
>;

export const useFiltersAdvanced: FilterController = ({ dependencies }) => {
  const { data, dispatch } = (dependencies as DependenciesProps).useOrdersContext();

  /**
   * onSelectPeriod
   *
   * @description Function to handle click over period filters in filter page
   * @param {string} value - Period
   * @example 1w, 15d, 3M
   */
  const onSelectPeriod = (value: string) => {
    dispatch({ type: ReduceActions.setFiltersByPeriodTmp, period: value });
  };
  /**
   * onSelectState
   *
   * @description Function to handle click over state filters in filter page
   * @param {string} value - State
   * @example deliverd, cancelled
   */
  const onSelectState = (value: string) => {
    dispatch({ type: ReduceActions.setFilterByStateTmp, state: value });
  };

  return {
    filtersDate: data.tmpFilterDates as Array<FilterState>,
    filtersStates: data.tmpFiltersState as Array<FilterState>,
    onSelectState,
    onSelectPeriod,
  };
};
