import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const Label = styled(Typography)`
  text-transform: uppercase;
`;

const Container = styled.div`
  margin-top: 0px;
  margin-bottom: ${(props) => props.theme.space('space-06')};
`;

export { Label, Container };
