import React from 'react';
import styled from 'styled-components';

const MenuPanel = styled.div`
  position: sticky;
  top: 66px;
  left: 16px;
`;

const NavPanel = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 3;
  background-color: #fff;
  border-bottom: 1px solid #c4c6cf;
  height: 56px;
`;

export { MenuPanel, NavPanel };
