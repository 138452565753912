/**
 * PageSkeleton
 *
 * @description Components for show skeleton shape when i18n and env vars
 * are loading. So this component will be used by providers when dependencies are laoding
 * @see @app/contexts/PublicEnv
 * @see @app/contexts/IntlProvider
 */
import React from 'react';
import { Shimmer } from '@app/shared/components/Shimmer';
import { CardSkeleton } from '@app/orders/presentation/components/CardSkeleton';
import { Container } from '@app/shared/components/Container';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import useIsWebView from '@app/shared/hooks/useIsWebView';

const PageSkeleton = () => {
  const isWebView = useIsWebView();

  if (!isWebView) {
    return <PeyaLoader position="center" />;
  }

  return (
    <Container>
      <div
        style={{
          height: 40,
          textAlign: 'left',
        }}
      >
        <Shimmer width={`100px`} height="20px" shape="square" />
      </div>
      <div style={{ paddingBottom: 16, display: 'flex', gap: 8, alignItems: 'center' }}>
        <Shimmer width={`24px`} height="24px" shape="square" />
        <Shimmer width={`38px`} height="14px" shape="square" />
        <Shimmer width={`86px`} height="28px" shape="square" />
        <Shimmer width={`86px`} height="28px" shape="square" />
      </div>
      <div>
        <CardSkeleton />
        <CardSkeleton />
        <CardSkeleton />
      </div>
    </Container>
  );
};

export { PageSkeleton };
