import React, { memo } from 'react';
import Translations from '@app/translations/globalTranslations';
import { Label, Container } from './OrderPartnerOpen.style';
import { Constants } from '@app/orders/infra/config/Constants';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import type { OrdersPartnerOpenProps, DependenciesProps } from './OrdersPartnerOpen.type';
import type { FC } from 'react';

export const OrderPartnerOpen: FC<OrdersPartnerOpenProps> = WithDependencies(
  memo(function OrderPartnerOpen({
    openTime = '00:00',
    orderState = '',
    vendorState = Constants.VENDOR_OPEN_STATE,
    dependencies,
  }) {
    const { formatMessage, formatDateToParts } = (dependencies as DependenciesProps).useIntl();

    if (Constants.DELIVERED_STATE === orderState) {
      const time = formatDateToParts(openTime, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
      });
      if (Constants.VENDOR_CLOSED_STATE === vendorState) {
        return (
          <Container>
            <Label token="font-label-highercontrast-medium" color="text-color-feedback-warning">
              {formatMessage(Translations['card.openTime.closed'])}
            </Label>
          </Container>
        );
      } else if (Constants.VENDOR_OPEN_LATE_STATE === vendorState) {
        return (
          <Container>
            <Label token="font-label-highercontrast-medium" color="text-color-feedback-warning">
              {formatMessage(Translations['card.openTime.label'], {
                openTime: `${time[6].value}${time[7].value}${time[8].value}`,
              })}
            </Label>
          </Container>
        );
      }
    }

    return null;
  }),
);
