import React, { memo, FC } from 'react';
import styled, { keyframes } from 'styled-components';

const SHAPES = {
  CIRCLE: 'circle',
  SQUARE: 'square',
};

const animation = keyframes`
   0% {
     background-position: -468px 0;
   }
   100% {
     background-position: 468px 0;
   }
`;

interface ShimmerProps extends React.HTMLAttributes<HTMLDivElement> {
  shape: Lowercase<keyof typeof SHAPES>;
  height: string;
  width: string;
  'data-testid'?: string;
}

const Shine = styled.div<ShimmerProps>`
  border-radius: ${(props) => (props.shape === SHAPES.CIRCLE ? '100%' : '6px')};
  height: ${(props) => props.height || '100%'};
  width: ${(props) => props.width || '100%'};
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-name: ${animation};
  -webkit-animation-name: ${animation};
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
`;

export const Shimmer: FC<ShimmerProps> = memo(
  ({ shape = 'circle', height = '100%', width = '100%', ...props }) => {
    return <Shine height={height} width={width} shape={shape} {...props} />;
  },
);
