import React from 'react';
import styled from 'styled-components';

const TagSection = styled.div`
  margin-bottom: ${(props) => props.theme.space('space-03')};
  & svg {
    width: 16px;
    height: 16px;
  }
`;

export { TagSection };
