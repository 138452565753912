import { Constants } from '@app/shared/config/constants';
import { getItem } from '@app/shared/utils/storage';
import { AxiosInstance } from 'axios';
import { Urls } from '@app/orders/infra/config/Urls';
import { OrdersRepository as IOrdersRepository } from '@app/orders/domain/repositories';
import {
  HistoryOrdersRequest,
  HistoryOrdersResponse,
  CurrentOrdersRequest,
  CurrentOrdersResponse,
} from '@app/orders/domain/models';

export class OrdersRepository implements IOrdersRepository {
  private http: AxiosInstance;
  constructor(http: AxiosInstance) {
    this.http = http;
  }
  public async getOrders(payload: HistoryOrdersRequest): Promise<HistoryOrdersResponse> {
    const result = await this.http.get<HistoryOrdersResponse>(Urls.ORDERS, {
      params: {
        mock: getItem(Constants.MOCK_FLAG, false),
        previous: payload.cursor,
        flow: payload.flow,
        ...payload.filters,
      },
      signal: payload.signal,
    });
    return result.data;
  }

  public async getCurrentOrders(payload: CurrentOrdersRequest): Promise<CurrentOrdersResponse> {
    const result = await this.http.get<CurrentOrdersResponse>(Urls.CURRENT_ORDERS, {
      params: {
        mock: getItem(Constants.MOCK_FLAG, false),
        flow: payload.flow,
      },
      signal: payload.signal,
    });
    return result.data;
  }
}
