import React, { memo } from 'react';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { Container, List, ListItem } from './FiltersOptions.style';
import { FiltersRadionButton } from '../FiltersRadioButtton';
import type { FC } from 'react';
import type { FiltersOptionsProps } from './FiltersOptions.type';
import type { FilterState } from '@app/orders/domain/models';

export const FiltersOptions: FC<FiltersOptionsProps> = memo(({ filters, onSelect }) => {
  return (
    <Container>
      <List>
        {filters ? (
          filters.map((element: FilterState) => {
            return (
              <ListItem key={element.value}>
                <Typography
                  token="font-body-midcontrast-sentence-medium"
                  color="text-color-action-enabled-loud"
                >
                  {element.label}
                </Typography>
                <div data-testid="radio">
                  <FiltersRadionButton
                    value={element.value}
                    selected={element.selected as boolean}
                    onSelect={() => onSelect(element.value)}
                  />
                </div>
              </ListItem>
            );
          })
        ) : (
          <></>
        )}
      </List>
    </Container>
  );
});
