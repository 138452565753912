import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 12px;
  padding-bottom: 4px;
  border-top: 1px solid rgb(219, 218, 222);
`;

const SideLeft = styled.div<{ enabled: boolean }>`
  width: 50%;
  text-align: center;
  position: relative;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.enabled ? 'pointer' : 'not-allowed')};
  &:after {
    content: ' ';
    position: absolute;
    top: 0px;
    right: 0px;
    width: 1px;
    height: 100%;
    background-color: #dbdade;
  }
  & > span:first-child {
    margin-right: 4px;
    width: 16px;
    height: 16px;
  }
  & > span:last-child {
    color: ${(props) =>
      props.enabled
        ? props.theme.color('shape-color-surface-secondary')
        : props.theme.color('text-color-action-disabled-default')};
  }
`;

const SideRight = styled.div<{ enabled: boolean }>`
  width: 50%;
  text-align: center;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.enabled ? 'pointer' : 'not-allowed')};
  & > span:first-child {
    margin-right: 4px;
    width: 16px;
    height: 16px;
  }
  & > span {
    color: ${(props) =>
      props.enabled
        ? props.theme.color('shape-color-surface-secondary')
        : props.theme.color('text-color-action-disabled-default')};
  }
`;

const ActionItem = styled.div<{ column?: number; enabled?: boolean }>`
  width: ${(props) => (props?.column ? 100 / props.column : '100')}%;
  text-align: center;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: ${(props) => (props.enabled ? 'pointer' : 'not-allowed')};
  & > span:first-child {
    margin-right: 4px;
    width: 16px;
    height: 16px;
  }
  &:last-child {
    &:after {
      content: ' ';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 1px;
      height: 100%;
      background-color: #dbdade;
    }
  }

  &:first-child {
    &:after {
      width: 0px;
    }
  }

  & > span:last-child {
    color: ${(props) =>
      props.enabled
        ? props.theme.color('shape-color-surface-secondary')
        : props.theme.color('text-color-action-disabled-default')};
  }
`;

export { SideLeft, SideRight, Container, ActionItem };
