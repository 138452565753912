import React from 'react';
import styled from 'styled-components';

const InsideContainer = styled.div`
  padding: ${(props) => props.theme.space('space-08')};
  align-items: center;
  position: relative;
  text-align: center;
  & > span {
    cursor: pointer;
  }
  & > h3 {
    display: inline-block;
  }
`;

const CartContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 6px;
  width: 58px;
`;

const IconContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 14px;
  width: 50px;
`;

export { InsideContainer, CartContainer, IconContainer };
