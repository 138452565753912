export const DEVICESOS = {
  ANDROID: 'AndroidOS',
  IOS: 'iOS',
  WEB: 'WEB',
};

export const DEVICES = {
  MOBILE: 'MOBILE',
  LAPTOP: 'LAPTOP',
  TABLET: 'TABLET',
  WEBVIEW: 'WEBVIEW',
};
