import React, { useState, useMemo } from 'react';
import { FWFFlags } from '@app/shared/config/fwfFlags';
import type { LayoutController, DependenciesProps } from './LayoutMobile.type';
import type { ContextType } from '@app/shared/contexts/PublicEnv';

const useLayoutMobile: LayoutController = ({ dependencies }) => {
  const dpn = dependencies as DependenciesProps;
  const [showFilterPage, setShowFilterPage] = useState(false);
  const { filterPageLoaded } = dpn.useTracker();
  const { flags } = dpn.useFWF();
  const { userId, user, location } = dpn.usePublicEnv() as ContextType;
  const query = dpn.useUrlQuery();
  const isWebView = dpn.useIsWebView();

  /**
   * toggleFilterPage
   *
   * @description Callback to be execute when user try to open
   * the filter page
   * @param {boolean} value
   */
  const toggleFilterPage = (value: boolean) => {
    if (value) {
      filterPageLoaded(query.get('flow') as string);
    }
    setShowFilterPage(value);
  };

  /**
   * showBottomNav
   *
   * @description Function to return a flag to know if show or not
   * bottom nav web version
   */
  const showBottomNav = useMemo(() => {
    if (isWebView) {
      return false;
    }

    if (flags[FWFFlags.showIconMenu]) {
      return flags[FWFFlags.showIconMenu];
    }

    return false;
  }, [flags, isWebView]);

  return {
    showFilterPage,
    toggleFilterPage,
    showBottomNav: showBottomNav,
    user: {
      isLoggedUser: userId || user?.id ? true : false,
      ...location,
    },
  };
};

export { useLayoutMobile };
