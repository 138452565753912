import React from 'react';
import { Switch, Route } from 'react-router-dom';
import loadable from '@loadable/component';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
/**
 * config
 */
import { Routes } from '@app/shared/config/routes';
/**
 * Pages
 */
import { Orders } from '@app/orders/main/Orders';
import { NotFound } from '@app/shared/components/NotFound';
const FeedbackPage = loadable(() => import('@app/feedback/main/Feedback'), {
  resolveComponent: (components) => components.Feedback,
  fallback: <PeyaLoader position="center" />,
});

const RouterApp = () => {
  return (
    <Switch>
      <Route exact path={Routes.MY_ORDERS}>
        <Orders />
      </Route>
      <Route exact path={Routes.NEW_FEEDBACK}>
        <FeedbackPage />
      </Route>
      <Route path={Routes.FEEDBACK}>
        <></>
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

export { RouterApp };
