import React, { FC } from 'react';
import { format } from '@pedidosya/currency-formatter';
import isFunction from 'lodash/isFunction';

type Props = {
  amount: number;
  country: string;
  children: (amount: string) => React.ReactNode | React.ReactNode;
};

const NumberFormatter: FC<Props> = ({ amount, children, country }) => {
  const formatedPrice = format(parseFloat(amount.toString()), country);

  if (isFunction(children)) {
    return children(formatedPrice?.price);
  }

  return <>{formatedPrice?.price}</>;
};

const Formatter = (amount: number, country: string) => {
  const formatedPrice = format(parseFloat(amount.toString()), country);
  return formatedPrice?.price;
};

export { NumberFormatter, Formatter };
