import { ExternalUrls } from '@app/shared/config/externalUrls';
import { ExternalHttpClient } from '@app/shared/utils/http';
import { Constants } from '@app/shared/config/constants';
import { ExternalRepository as IExternalRepository } from './externalRepository.type';
import { AddressResponse } from '@app/shared/models/Address.response';
import { CartResponse } from '@app/shared/models/Cart.response';
import { RestaurantResponse } from '@app/shared/models/Restaurants.response';
import { RedirectLoginResponse } from '@app/shared/models/RedirectLogin.response';

export class ExternalRepository implements IExternalRepository {
  constructor() {}

  public async createCart(orderId: string, signal: AbortSignal): Promise<CartResponse> {
    const result = await ExternalHttpClient.getInstance().post(
      `${ExternalUrls.CART}`,
      { fromOrder: parseInt(orderId), userApplication: Constants.WEB_APPLICATION },
      { signal },
    );

    return result?.data;
  }

  public async setCart(
    cartId: string,
    orderId: string,
    addressId: number,
    signal: AbortSignal,
  ): Promise<CartResponse> {
    const result = await ExternalHttpClient.getInstance().put(
      `${ExternalUrls.CART}/${cartId}`,
      {
        fromOrder: parseInt(orderId),
        userApplication: Constants.WEB_APPLICATION,
        address: addressId,
      },
      { signal },
    );

    return result?.data;
  }
  public async getMyAddress(countryId: number, signal: AbortSignal): Promise<AddressResponse> {
    const result = await ExternalHttpClient.getInstance().get(
      `${ExternalUrls.MY_ADDRESS}?countryId=${countryId}`,
      { signal },
    );

    return result?.data?.data?.addresses;
  }
  public async getRestaurant(
    restaurantId: number,
    signal: AbortSignal,
  ): Promise<RestaurantResponse> {
    const result = await ExternalHttpClient.getInstance().get(
      `${ExternalUrls.RESTAURANT}/${restaurantId}`,
      {
        signal,
      },
    );

    return result?.data?.restaurant;
  }
  public async getRedirectLogin(): Promise<RedirectLoginResponse> {
    const result = await ExternalHttpClient.getInstance().get(`${ExternalUrls.LOGIN}`);
    return result?.data;
  }
}
