import { FilterDates, FilterDatesType } from '@app/orders/infra/config/FilterDates';

export const getBoundariesDatesForPeriod = (period: string) => {
  const result: FilterDatesType = (FilterDates as any)[period];

  if (!result) {
    throw Error('period is invalid');
  }
  const dates = result.getDate();

  return { dateStart: dates.dateStart, dateEnd: dates.dateEnd };
};
