import React, { useReducer, useEffect, useState, FC } from 'react';
import GeneralTranslations from '@app/translations/globalTranslations';
import { InitialValue, OrdersContext } from './context';
import { OrdersReducer } from './reducer';
import { useIntl } from 'react-intl';
import { FilterStates } from '@app/orders/infra/config/FilterStates';
import { FilterDates } from '@app/orders/infra/config/FilterDates';
import { useWindowSize } from '@app/shared/hooks/useWindowSize';
import { Constants } from '@app/orders/infra/config/Constants';
import { Actions } from './actions';

type OrdersProviderProps = {
  children: JSX.Element | Array<JSX.Element>;
};

export const OrdersProvider: FC<OrdersProviderProps> = ({ children }) => {
  const { formatMessage } = useIntl();
  const [data, dispatch] = useReducer(OrdersReducer, InitialValue);
  const [countFilters, setCountFilters] = useState(0);
  const [countFiltersTmp, setCountFiltersTmp] = useState(0);
  const { width } = useWindowSize();

  useEffect(() => {
    dispatch({
      type: Actions.setFilterStates,
      filterStates: FilterStates.map((element) => {
        return {
          value: element.value,
          label: formatMessage((GeneralTranslations as any)[element.translationKey]),
          selected: false,
        };
      }),
    });

    dispatch({
      type: Actions.setFilterDates,
      filterDates: Object.values(FilterDates).map((element) => {
        return {
          value: element.value,
          label: formatMessage((GeneralTranslations as any)[element.translationKey]),
          selected: false,
        };
      }),
    });
  }, [dispatch, formatMessage]);

  useEffect(() => {
    let filters = 0;
    if (data.filters.state.length > 0) {
      filters += 1;
    }
    if (data.filters.dateStart || data.filters.dateEnd) {
      filters += 1;
    }
    setCountFilters(filters);
  }, [data.filters.state, data.filters.dateEnd, data.filters.dateStart]);

  useEffect(() => {
    let filters = 0;
    if (data?.filtersTemp && data.filtersTemp.state.length > 0) {
      filters += 1;
    }
    if (data?.filtersTemp && (data.filtersTemp.dateStart || data.filtersTemp.dateEnd)) {
      filters += 1;
    }
    setCountFiltersTmp(filters);
  }, [data?.filtersTemp?.state, data?.filtersTemp?.dateEnd, data?.filtersTemp?.dateStart]);

  const contextValue = {
    data,
    dispatch,
    countFilters,
    countFiltersTmp,
    isWeb: Constants.MOBILE_LAYOUT_SIZE < width,
  };

  return <OrdersContext.Provider value={contextValue}>{children}</OrdersContext.Provider>;
};
