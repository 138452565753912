import React, { useEffect, useState } from 'react';
import { useWindowSize } from '@app/shared/hooks/useWindowSize';
import { CardSkeleton } from '@app/orders/presentation/components/CardSkeleton';
import { Constants } from '@app/orders/infra/config/Constants';
import type { FC } from 'react';

type Props = {
  paddingStart: number;
};

export const LoadingSkeleton: FC<Props> = ({ paddingStart }) => {
  const { height } = useWindowSize();
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    const area = height - paddingStart - Constants.CARD_MARGIN_BETWEEN;
    setQuantity(
      Math.max(
        0,
        Math.trunc(area / (Constants.CARD_SKELETON_HEIGHT + Constants.CARD_MARGIN_BETWEEN)),
      ),
    );
  }, [height, paddingStart]);
  return (
    <>
      {[...Array(quantity || 0).keys()].map((key) => {
        return <CardSkeleton key={key} />;
      })}
    </>
  );
};
