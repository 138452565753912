/**
 * AndroidEventProvider
 *
 * @description This provider was created to fix and problem with android device
 * and the bottom nav, this provider consiste in execute a web event to android
 * for show bottom nav because when user click over rate a order the bottom will
 * be hide, so this provider help to show againa the bottom nav
 */
import React, { useEffect } from 'react';
import { fireBottomNavShow, usePublicEnv, ContextType } from '@app/shared/contexts/PublicEnv';
import { useWebViewEvents } from '@app/shared/hooks/useWebViewEvents';
import type { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const AndroidEventProvider: FC<Props> = ({ children }) => {
  const { fireBottomNavEvents, userId } = usePublicEnv() as ContextType;
  const { fire } = useWebViewEvents();

  useEffect(() => {
    const fireEvent = fireBottomNavShow(fire, fireBottomNavEvents);
    fireEvent();
  }, [fireBottomNavEvents, fire, userId]);

  return <>{children}</>;
};

export { AndroidEventProvider };
