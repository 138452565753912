import React, { Children, cloneElement, memo } from 'react';
import { useHistory } from 'react-router-dom';
import type { FC, ReactNode, ReactElement } from 'react';

/**
 * Components
 */
import { Container } from '@app/shared/components/Container';
import { Grid, Col } from '@app/shared/components/Grid';
import { NavBar } from '@app/shared/components/NavBar';
import { NavPanel, MenuPanel } from './LayoutWebInside.style';
import { LoadableMenu } from '@app/orders/presentation/components/LoadableMenu';

type Props = {
  children: ReactNode;
};

type PropsChildren = {
  children: ReactNode;
  [key: string]: any;
};

const TopSide: FC<PropsChildren> = ({ children }) => {
  return <>{children}</>;
};
TopSide.displayName = 'TopSide';

const LeftSide: FC<PropsChildren> = ({ children, ...props }) => {
  return (
    <Col size={1} {...props}>
      {children}
    </Col>
  );
};
LeftSide.displayName = 'LeftSide';

const CenterSide: FC<PropsChildren> = ({ children, ...props }) => {
  return (
    <Col size={2} {...props}>
      {children}
    </Col>
  );
};
CenterSide.displayName = 'CenterSide';

const RightSide: FC<PropsChildren> = ({ children, ...props }) => {
  return (
    <Col size={1} {...props}>
      {children}
    </Col>
  );
};
RightSide.displayName = 'RightSide';

type SubComponents = {
  center: FC<PropsChildren>;
  right: FC<PropsChildren>;
  top: FC<PropsChildren>;
  left: FC<PropsChildren>;
};

const LayoutWebInside: FC<Props> & SubComponents = memo(({ children }: Props) => {
  const { go } = useHistory();
  const elements = Children.toArray(children) as Array<ReactElement>;
  const Center: ReactElement = elements.find(
    (element: React.ReactElement) => (element.type as any).displayName === 'CenterSide',
  ) as React.ReactElement;
  const Right = elements.find(
    (element) => (element.type as any).displayName === 'RightSide',
  ) as React.ReactElement;
  const Top = elements.find(
    (element) => (element.type as any).displayName === 'TopSide',
  ) as React.ReactElement;
  const Left = elements.find(
    (element) => (element.type as any).displayName === 'LeftSide',
  ) as React.ReactElement;

  return (
    <>
      {Top ? (
        cloneElement(Top, { children: Top?.props?.children })
      ) : (
        <NavPanel>
          <NavBar callback={() => go(-1)} />
        </NavPanel>
      )}
      <Container pTop="56" pBottom="0" pRight="0">
        <Grid>
          {Left ? (
            cloneElement(Left, { children: Left?.props?.children })
          ) : (
            <Col size={1}>
              <MenuPanel data-testid="menu_panel">
                <LoadableMenu />
              </MenuPanel>
            </Col>
          )}
          {Center && cloneElement(Center, { children: Center?.props?.children })}
          {Right && cloneElement(Right, { children: Right?.props?.children })}
        </Grid>
      </Container>
    </>
  ) as any;
});

LayoutWebInside.center = CenterSide;
LayoutWebInside.right = RightSide;
LayoutWebInside.top = TopSide;
LayoutWebInside.left = LeftSide;

export { LayoutWebInside };
