import React, { memo } from 'react';
import GlobalTranslations from '@app/translations/globalTranslations';
import Close from '@pedidosya/web-fenix/icons/Close';
import { createPortal } from 'react-dom';
import {
  Container,
  Headers,
  Title,
  CloseContainer,
  CTAContainer,
  CTAButtonContainer,
  InsideContainer,
} from './FiltersModal.style';
import { useFiltersModal } from './useFiltersModal';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import type { FC } from 'react';
import type { DependenciesProps, FiltersModalProps } from './FiltersModal.type';
/**
 * Components
 */
import { FiltersClear } from '@app/orders/presentation/components/FiltersAdvanced/FiltersClear';
import { FiltersApply } from '@app/orders/presentation/components/FiltersAdvanced/FiltersApply';
import { FiltersAdvanced } from '@app/orders/presentation/components/FiltersAdvanced';

export const FiltersModal: FC<FiltersModalProps> = WithDependencies(
  memo(({ isOpen, onClose, dependencies }) => {
    const { formatMessage } = (dependencies as DependenciesProps).useIntl();
    const { windowHeight, insideRef } = useFiltersModal({ isOpen, dependencies });

    if (!isOpen) return null;

    return createPortal(
      <Container height={windowHeight}>
        <InsideContainer ref={insideRef}>
          <CloseContainer onClick={onClose} data-testid="close">
            <Close size="medium" />
          </CloseContainer>
          <Headers>
            <Title token="font-headline-highcontrast-small" color="text-color-action-enabled-loud">
              {formatMessage(GlobalTranslations['filters_advanced.title'])}
            </Title>
            <FiltersClear />
          </Headers>
          <FiltersAdvanced />
          <CTAContainer>
            <CTAButtonContainer>
              <FiltersApply onApply={onClose} />
            </CTAButtonContainer>
          </CTAContainer>
        </InsideContainer>
      </Container>,
      document.body,
    );
  }),
);
