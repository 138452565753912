import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { BadgeContainer } from './Badge.style';
import type { FC } from 'react';

type Props = {
  count: number;
  type: 'invert' | string;
};

export const Badge: FC<Props> = memo(({ count = 0, type }) => {
  return <BadgeContainer type={type}>{count}</BadgeContainer>;
});
