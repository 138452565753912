import React from 'react';
import { LinkContext } from './context';
import { usePublicEnv, ContextType } from '@app/shared/contexts/PublicEnv';
import { generateLink } from '@app/shared/utils/deeplink';
import { DEVICES } from '@app/shared/config/device';
import { useWebViewEvents } from '@app/shared/hooks/useWebViewEvents';
import { getWindow } from '@app/shared/utils/window';
import { useHistory } from 'react-router-dom';
import type { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

/**
 * LinkProvider
 *
 * @description Provider for generate link base on device and deviceOS will generate a link or deeplink
 * @param {Object} Props - React Props
 * @returns React.Node
 */
const LinkProvider: FC<Props> = ({ children }) => {
  const { device, deviceOS } = usePublicEnv() as ContextType;
  const { fire } = useWebViewEvents();
  const { push } = useHistory();

  const contextValue = {
    /**
     * redirect
     *
     * @description Function for make a redirect based on device
     * @param {ACTIONS} action  - Action to generate link
     * @param {Object} params - Params required to build de url
     */
    redirect: (action: string, params: any) => {
      const wn: { singleSpaNavigate?(url: string): void } & Window & typeof globalThis =
        getWindow();
      wn.location.href = generateLink(device, deviceOS, action, params || {});
    },
    /**
     * navigate
     *
     * @description Function to make a single spa navigate to another microsite
     * @param {ACTIONS} action  - Action to generate link
     * @param {Object} params - Params required to build de url
     */
    navigate: (action: string, params: any) => {
      const wn: { singleSpaNavigate?(url: string): void } & Window & typeof globalThis =
        getWindow();
      const url = generateLink(device, deviceOS, action, params || {});
      wn.singleSpaNavigate && wn.singleSpaNavigate(url);
    },
    /**
     * emitEvent
     * @param {*name} name - Name of event to send to webview
     * @param {*} params - Extra params to send to webview
     */
    emitEvent: (name: string, params: any) => {
      if (DEVICES.MOBILE === device || DEVICES.WEBVIEW === device) {
        fire(name, params);
      }
    },
    /**
     * redirectRouter
     * @param {string} action - Action name
     * @param {Object} params - Params to pass to url
     */
    redirectRouter: (action: string, params: any) => {
      const url = generateLink(device, deviceOS, action, params || {});
      push(url);
    },
  };

  return <LinkContext.Provider value={contextValue}>{children}</LinkContext.Provider>;
};

export { LinkProvider };
