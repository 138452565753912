import React, { memo, useMemo, useEffect, useCallback, useState, useRef } from 'react';
import { FWFContext } from './context';
import { usePublicEnv, ContextType } from '@app/shared/contexts/PublicEnv';
import { FWFFlags } from '@app/shared/config/fwfFlags';
import {
  initialize as initializeFWF,
  getFlag as getFlagOrigin,
} from '@pedidosya/web-native-bridges/fwf';
import type { FC, ReactNode } from 'react';
import isFunction from 'lodash/isFunction';

type Props = {
  children: ReactNode;
  fwf: Promise<any> | null;
  config: any;
};

const FWFProvider: FC<Props> = memo(function FWFProvider({ children, fwf, config }) {
  const { userId, country, device, deviceOS, appVersion, platform } = usePublicEnv() as ContextType;
  const [fwfActions, setFwfActions] = useState({});
  const [getFlagFn, setFlagFn] = useState<{ (name: string): any } | null>(null);
  const [flags, setFlags] = useState({});
  const isMounted = useRef(true);

  const configure = useCallback(async () => {
    await initializeFWF({
      config,
      context: {
        userId: userId?.toString(),
        country: country?.shortName,
        device,
        deviceOS: !deviceOS ? 'web' : deviceOS,
        version: appVersion,
        platform,
      },
    });
    setFlagFn(() => getFlagOrigin);
  }, [config]);

  const initialize = useCallback(async () => {
    const promise = await fwf;
    promise?.setContext({
      userId: userId?.toString(),
      country: country?.shortName,
      device,
      deviceOS: !deviceOS ? 'web' : deviceOS,
      version: appVersion,
      platform,
    });
    setFlagFn(() => promise?.getFlag);
    setFwfActions(promise);
  }, [userId, setFwfActions, fwf]);

  /**
   * getFlag
   *
   * @description Function to call to FWF to get
   * flag value
   * @param {string} name - name of flag
   * @param {Object} client - fwf client
   * @returns {Object}
   */
  const getFlag = async (name: string) => {
    if (getFlagFn) {
      const result = await getFlagFn(name);
      if (!result?.isEnabled) {
        return { [name]: false };
      }
      return { [name]: result?.variation === 'Control' ? false : true };
    }

    return { [name]: false };
  };

  /**
   * reduceFlags
   *
   * @description Function to assign flags value
   * to state
   */
  const reduceFlags = useCallback((flags: Array<any>) => {
    if (flags) {
      const result = flags.reduce((acc, current) => {
        acc = { ...acc, ...current };
        return acc;
      }, {});

      setFlags(result);
    }
  }, []);

  /**
   * Initializing the fwf client
   */
  useEffect(() => {
    if (fwf) {
      initialize();
    } else {
      configure();
    }
  }, [fwf, initialize, configure]);

  /**
   * Consulting all flags required
   */
  useEffect(() => {
    isMounted.current = true;
    if (isFunction(getFlagFn) && isMounted.current) {
      const values = Object.values(FWFFlags).map((element) => {
        return getFlag(element);
      });

      Promise.all(values).then((values) => {
        reduceFlags(values);
      });
    }

    return () => {
      isMounted.current = false;
    };
  }, [getFlagFn, reduceFlags]);

  const value = useMemo(
    () => ({
      fwf,
      fwfActions,
      flags,
    }),
    [fwf, fwfActions, flags],
  );

  return <FWFContext.Provider value={value}>{children}</FWFContext.Provider>;
});

export { FWFProvider };
