import React, { memo } from 'react';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Filter from '@pedidosya/web-fenix/icons/Filter';
import Translations from '@app/translations/globalTranslations';
import { Swiper } from '@app/shared/components/Swiper';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import type { FC } from 'react';
import type { DependenciesProps, OrdersFilterProps } from './OrdersFilter.type';
/**
 * Styled
 */
import {
  TagContainer,
  FiltersContainer,
  FilterIconContainer,
  SwiperSection,
} from './OrdersFilter.style';
/**
 * Components
 */
import { Chip } from '@app/shared/components/Chip';
import { Badge } from '@app/shared/components/Badge';

export const OrdersFilter: FC<OrdersFilterProps> = WithDependencies(
  memo(({ filters, onSelect, badgeCount, openFiltersPage, dependencies }) => {
    const { formatMessage } = (dependencies as DependenciesProps).useIntl();
    return (
      <FiltersContainer>
        <FilterIconContainer onClick={openFiltersPage}>
          <Filter size="medium" />
          {badgeCount ? <Badge count={badgeCount} type="default" /> : null}
          <Typography
            token="font-label-highercontrast-underline-medium"
            color="text-color-action-enabled-loud"
          >
            {formatMessage(Translations['filters.title'])}
          </Typography>
        </FilterIconContainer>
        <SwiperSection>
          <Swiper>
            {filters.map((element) => {
              return (
                <TagContainer key={element.value}>
                  <Chip
                    label={element.label}
                    value={element.value}
                    onSelect={onSelect}
                    selected={element.selected as boolean}
                  />
                </TagContainer>
              );
            })}
          </Swiper>
        </SwiperSection>
      </FiltersContainer>
    );
  }),
);
