import React, { memo } from 'react';
import Translations from '@app/translations/globalTranslations';
import Button from '@pedidosya/web-fenix/atoms/Button';
import { useFiltersApply } from './useFiltersApply';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import type { FC } from 'react';
import type { DependenciesProps, FiltersApplyProps } from './FiltersApply.type';

export const FiltersApply: FC<FiltersApplyProps> = WithDependencies(
  memo(({ onApply, disabled = false, dependencies }: FiltersApplyProps) => {
    const { formatMessage } = (dependencies as DependenciesProps).useIntl();
    const { enable, applyFilters } = useFiltersApply({ onApply, disabled, dependencies });
    return (
      <Button
        label={formatMessage((Translations as any)['filters_advanced.apply.button'])}
        hierarchy="primary"
        size="large"
        state={!enable || disabled ? 'disabled' : 'enabled'}
        onClick={applyFilters}
        fullWidth={true}
        type="button"
      />
    );
  }),
);
