import { createContext, useContext } from 'react';

export type ContexType = {
  fwf: Promise<any> | null;
  flags: any;
  fwfActions?: any;
};

export const FWFContext = createContext<ContexType>({ fwf: null, flags: {} });

export const useFWF = () => {
  return useContext(FWFContext);
};
