import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Translation from '@app/translations/globalTranslations';
import { useIntl } from 'react-intl';
import { MonthStyle, HourStyle } from './DateFormatter.style';

const DateFormatter = memo(({ date }) => {
  const { formatMessage, formatDateToParts } = useIntl();
  const [day, litDay, month, litMonth, year, litYear, hour, literal, minute] = formatDateToParts(
    date,
    {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    },
  );

  return (
    <>
      <MonthStyle token="font-label-midcontrast-small" color="text-color-secondary">
        {formatMessage(Translation['month.format'], {
          day: day.value,
          month: month.value.replace('.', ''),
        })}
      </MonthStyle>
      <HourStyle token="font-label-midcontrast-small" color="text-color-secondary">
        {formatMessage(Translation['hour.format'], { hour: hour.value, minute: minute.value })}
      </HourStyle>
    </>
  );
});

DateFormatter.defaultProps = {
  date: '',
};

DateFormatter.propTypes = {
  date: PropTypes.string.isRequired,
};

export { DateFormatter };
