import { FilterStates } from '@app/orders/infra/config/FilterStates';

export const EmptyStateRule = {
  name: 'empty_state',
  rules: [
    {
      name: 'No orders, no current orders',
      description: 'No orders, no current orders',
      when: {
        closure: 'checkOption',
        orders: 0,
        currentOrders: 0,
        filter: {
          orderState: [],
          dateStart: '',
          dateEnd: '',
        },
      },
      then: {
        closure: 'getTranslation',
        title: 'emptyState.noOrders.title',
        description: 'emptyState.noOrders.description',
        titleStyle: 'big',
        buttonText: 'emptyState.noOrders.makeOrder',
        key: 'new_order',
        bag: 'bag',
      },
    },
    {
      name: 'No orders, with current Orders, without filter',
      description: 'No orders, with current Orders, without filter',
      when: {
        closure: 'checkOption',
        orders: 0,
        currentOrders: '> 0',
        filter: {
          orderState: [],
          dateStart: '',
          dateEnd: '',
        },
      },
      then: {
        closure: 'getTranslation',
        title: 'emptyState.noOrders.withCurrent.title',
        description: 'emptyState.noOrders.withCurrent.description',
        titleStyle: 'small',
        bag: 'bag_small',
      },
    },
    {
      name: 'No orders, with current Orders, with filter delivered',
      description: 'No orders, with current Orders, with filter delivered',
      when: {
        closure: 'checkOption',
        orders: 0,
        currentOrders: '> 0',
        filter: {
          orderState: [FilterStates[0].value],
          except: [FilterStates[1].value],
          dateStart: '',
          dateEnd: '',
        },
      },
      then: {
        closure: 'getTranslation',
        title: 'emptyState.noOrders.withCurrent.title',
        description: 'emptyState.noOrders.withCurrent.description',
        titleStyle: 'small',
        bag: 'bag_first',
      },
    },
    {
      name: 'No orders, with current Orders, with filter cancelled',
      description: 'No orders, with current Orders, with filter cancelled',
      when: {
        closure: 'checkOption',
        orders: 0,
        currentOrders: '> 0',
        filter: {
          orderState: [FilterStates[1].value],
          except: [FilterStates[0].value],
          dateStart: '',
          dateEnd: '',
        },
      },
      then: {
        closure: 'getTranslation',
        title: 'emptyState.noOrders.withCurrent.cancelled.title',
        description: 'emptyState.noOrders.withCurrent.cancelled.description',
        titleStyle: 'small',
        bag: 'bag_small',
      },
    },
    {
      name: 'No orders, with current Orders, with filters',
      description: 'No orders, with current Orders, with filters',
      when: {
        closure: 'checkOption',
        orders: 0,
        currentOrders: '> 0',
        filter: {
          orderState: [FilterStates[1].value, FilterStates[0].value],
          dateStart: '!null',
          dateEnd: '!null',
        },
      },
      then: {
        closure: 'getTranslation',
        title: 'emptyState.noOrders.withCurrent.filtered_empty.title',
        description: 'emptyState.noOrders.withCurrent.filtered_empty.description',
        titleStyle: 'small',
        bag: 'bag_small',
      },
    },
    {
      name: 'Orders, no current Orders, with any filters',
      description: 'Orders, no current Orders, with any filters',
      when: {
        closure: 'checkOption',
        orders: 0,
        currentOrders: 0,
        filter: {
          orderState: [FilterStates[1].value, FilterStates[0].value],
          dateStart: '!null',
          dateEnd: '!null',
        },
      },
      then: {
        closure: 'getTranslation',
        title: 'emptyState.noOrders.withCurrent.filtered_empty.title',
        description: 'emptyState.noOrders.withCurrent.filtered_empty.description',
        titleStyle: 'big',
        buttonText: 'emptyState.noOrders.withCurrent.button.clearFilters',
        key: 'clear_filters',
        bag: 'filter',
      },
    },
    {
      name: 'Orders, no current Orders, with any state filter',
      description: 'Orders, no current Orders, with any state filters',
      when: {
        closure: 'checkOption',
        orders: 0,
        currentOrders: 0,
        filter: {
          orderState: [FilterStates[1].value, FilterStates[0].value],
          dateStart: '',
          dateEnd: '',
        },
      },
      then: {
        closure: 'getTranslation',
        title: 'emptyState.noOrders.withCurrent.filtered_empty.title',
        description: 'emptyState.noOrders.withCurrent.filtered_empty.description',
        titleStyle: 'big',
        buttonText: 'emptyState.noOrders.withCurrent.button.clearFilters',
        key: 'clear_filters',
        bag: 'filter',
      },
    },
  ],
};
