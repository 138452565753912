import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const Card = styled.div`
  border: 1px solid #dbdade;
  box-sizing: border-box;
  padding: 12px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  min-width: 300px;
`;

const CardContainer = styled.div<{ onClick: any }>`
  flex-wrap: wrap;
  display: flex;
  cursor: pointer;
`;

const VendorName = styled(Typography)`
  max-width: 100%;
  margin-bottom: ${(props) => props.theme.space('space-02')};
  margin-top: ${(props) => props.theme.space('space-02')};
`;

const ImageContainer = styled.div`
  margin-right: 12px;
  align-self: start;
`;

const ContentContainer = styled.div`
  align-self: start;
  flex: 1 1;
`;

const ButtonsContainer = styled.div`
  text-align: right;
`;

const ActionsContainer = styled.div`
  width: 100%;
`;

const TagSection = styled.div`
  margin-bottom: 10px;
  & > div {
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.02em;
    background: #100423;
    color: #fff;
    & > div > svg {
      fill: #fff;
      margin-right: 4px;
    }
  }
`;

export {
  Card,
  ButtonsContainer,
  VendorName,
  ImageContainer,
  ContentContainer,
  ActionsContainer,
  CardContainer,
  TagSection,
};
