import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getItem, setItem } from '@app/shared/utils/storage';
import { Constants } from '@app/shared/config/constants';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Switch from '@pedidosya/web-fenix/atoms/Switch';
import styled from 'styled-components';

const MockContainer = styled.div`
  display: flex;
  padding-right: 16px;
  & > span:first-child {
    margin-top: 4px;
    margin-right: 8px;
  }
`;

const getInitialValue = () => {
  try {
    const value = getItem(Constants.MOCK_FLAG);
    return value && value === 'false' ? false : true || true;
  } catch (e) {}
};

const MockSwitch = () => {
  const { go } = useHistory();
  const [state, setState] = useState(getInitialValue());

  const onChange = () => {
    setState(!state);
    setItem(Constants.MOCK_FLAG, !state);
    go(0);
  };

  return (
    <MockContainer>
      <Typography component="span" token="font-body-midcontrast-sentence-small">
        Mock
      </Typography>
      <Switch selected={state} onClick={onChange} />
    </MockContainer>
  );
};

export { MockSwitch };
