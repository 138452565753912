import React, { memo } from 'react';
import Tag from '@pedidosya/web-fenix/atoms/Tag';
import Time from '@pedidosya/web-fenix/icons/Time';
import { TagSection } from './OrderDropOff.style';
import type { OrderDropOffProps } from './OrderDropOff.type';
import type { FC } from 'react';

export const OrderDropoff: FC<OrderDropOffProps> = memo(({ time }) => {
  return (
    <TagSection>
      <Tag label={time} borderRadius="all" category="loud" icon={<Time size="small" />} />
    </TagSection>
  );
});
