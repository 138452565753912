import React, { memo } from 'react';
import Translations from './OrderActions.translation';
import RatingIcon from '@pedidosya/web-fenix/icons/RatingIcon';
import Reload from '@pedidosya/web-fenix/icons/Reload';
import HelpCenter from '@pedidosya/web-fenix/icons/HelpCenter';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { Container, ActionItem } from './OrderActions.style';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import type { OrderActionsProps, DependenciesProps } from './OrderActions.type';
import type { FC } from 'react';
import { useOrderActions } from './useOrderActions';

export const OrderActionsHelpCenter: FC<OrderActionsProps> = WithDependencies(
  memo(
    ({
      repeatEnabled,
      rateEnabled,
      onRepeat,
      onRate,
      dependencies,
      businessType,
      helpCenterEnabled = false,
      orderCreated = '',
      orderId,
      showActions = true,
      status = '',
    }) => {
      const { formatMessage } = (dependencies as DependenciesProps).useIntl();
      const {
        repeatAction,
        rateAction,
        helpCenterAction,
        actionsEnabled,
        showHelpCenter,
        showFeedback,
      } = useOrderActions({
        dependencies,
        onRate,
        onRepeat,
        rateEnabled,
        repeatEnabled,
        businessType,
        helpCenterEnabled,
        orderCreated,
        orderId,
        showActions,
        status,
      });

      return (
        <Container>
          {!showHelpCenter && showActions ? (
            <ActionItem
              enabled={rateEnabled}
              role="button"
              tabIndex={-1}
              onClick={rateAction}
              column={actionsEnabled}
            >
              <span>
                <RatingIcon
                  size="small"
                  color={
                    rateEnabled
                      ? 'shape-color-surface-secondary'
                      : 'text-color-action-disabled-default'
                  }
                />
              </span>
              <Typography token="font-label-highercontrast-underline-medium">
                {formatMessage(Translations['actions.rate'])}
              </Typography>
            </ActionItem>
          ) : (
            <></>
          )}
          {showHelpCenter ? (
            <ActionItem
              role="button"
              enabled={true}
              tabIndex={1}
              onClick={helpCenterAction}
              column={actionsEnabled}
            >
              <span>
                <HelpCenter size="small" color="shape-color-surface-secondary" />
              </span>
              <Typography token="font-label-highercontrast-underline-medium">
                {formatMessage(Translations['actions.help'])}
              </Typography>
            </ActionItem>
          ) : (
            <></>
          )}
          {showActions ? (
            <ActionItem
              enabled={repeatEnabled}
              role="button"
              tabIndex={0}
              onClick={repeatAction}
              column={actionsEnabled}
            >
              <span>
                <Reload
                  size="small"
                  color={
                    repeatEnabled
                      ? 'shape-color-surface-secondary'
                      : 'text-color-action-disabled-default'
                  }
                />
              </span>
              <Typography token="font-label-highercontrast-underline-medium">
                {formatMessage(Translations['actions.retry'])}
              </Typography>
            </ActionItem>
          ) : null}
        </Container>
      );
    },
  ),
);
