import React, { useEffect, useRef } from 'react';
import { Constants } from '@app/orders/infra/config/Constants';
import type { FiltersController, DependenciesProps } from './FiltersModal.type';

export const useFiltersModal: FiltersController = ({ isOpen, dependencies }) => {
  const { height } = (dependencies as DependenciesProps).useWindowSize();
  const insideRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isOpen]);

  useEffect(() => {
    if (insideRef.current) {
      insideRef.current.style.minHeight = `${
        insideRef.current.clientHeight + Constants.APPLY_FILTER_HEIGHT
      }px`;
    }
  }, [height, isOpen]);

  return {
    windowHeight: height,
    insideRef,
  };
};
