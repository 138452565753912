import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const ContainerHub = styled.div<{ ref?: any }>`
  width: auto;
  height: 40px;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: relative;
`;

const Badge = styled(Typography)`
  min-width: 12px;
  padding: 1px;
  text-align: center;
  position: absolute;
  top: 8px;
  right: -5px;
  background-color: ${(props) => props.theme.color('#EA044E')};
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  background-clip: padding-box;
`;

const CouchmarkContainer = styled.div`
  position: absolute;
  top: 70px;
  right: 260px;
  z-index: 2;
`;

export { CouchmarkContainer, ContainerHub, Badge };
