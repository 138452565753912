import React, { useRef, useState, useEffect } from 'react';
import { Constants } from '@app/orders/infra/config/Constants';
import { ACTIONS } from '@app/shared/utils/deeplink';
import type { OrderController, DependenciesProps } from './CurrentOrders.type';
import type { ContextType } from '@app/shared/contexts/PublicEnv';
import debounce from 'lodash/debounce';

export const useCurrentOrders: OrderController = ({ dependencies }) => {
  const { position, down } = (dependencies as DependenciesProps).useScroll();
  const [swipeHeight, setSwipeHeight] = useState<number>(1);
  const [containerWidth, setContainerWidth] = useState(0);
  const { swipedCards } = (dependencies as DependenciesProps).useTracker();
  const { redirect } = (dependencies as DependenciesProps).useLinkContext();
  const swipeSectionRef = useRef<HTMLElement>();
  const swipeSectionHeight = useRef<number>();
  const containerRef = useRef<HTMLElement>();
  const query = (dependencies as DependenciesProps).useUrlQuery();

  useEffect(() => {
    if (containerRef.current?.clientWidth) {
      setContainerWidth(containerRef.current.clientWidth);
    }
  }, []);

  useEffect(() => {
    if (swipeSectionRef.current?.clientHeight) {
      swipeSectionHeight.current = swipeSectionRef.current?.clientHeight;
    }
  }, []);

  useEffect(() => {
    const element = swipeSectionRef.current;
    const initialHeight = swipeSectionHeight.current as number;
    let finalHeight = swipeSectionHeight.current as number;
    if (element) {
      if (down) {
        finalHeight = Math.max(0, initialHeight - position);
        element.style.height = `${finalHeight + 2}px`;
      } else {
        finalHeight = Math.max(0, Math.min(initialHeight, initialHeight - position));
        element.style.height = `${finalHeight + 2}px`;
      }
    }
    setSwipeHeight(finalHeight);
  }, [position]);

  /**
   * trackOnSwipe
   *
   * @description Function to track event when user
   * make swipe on cards
   */
  const trackOnSwipe = debounce(() => {
    swipedCards(query.get('flow') as string);
  }, 500);
  /**
   * onClickCard
   *
   * @description Function to capture click over card
   * and fire deeplink to OS
   * @param {string} orderId - Order Id
   * @param {string} code - Order code
   */
  const onClickCard = (orderId: number, country: string) => {
    redirect(ACTIONS.ORDER_STATUS, { id: orderId, country });
  };

  return {
    swipeSectionRef,
    swipeHeight,
    trackOnSwipe,
    onClickCard,
    containerRef,
    containerWidth,
    showHelpCenter: true,
  };
};
