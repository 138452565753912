import { useState, useCallback, Dispatch, SetStateAction } from 'react';

type Func = (node: HTMLElement) => SetStateAction<HTMLElement | null>;
type Props = [HTMLElement | null, Dispatch<SetStateAction<(node: HTMLElement) => void | null>>];

function useStateRef(processNode: Func): Props {
  const [node, setNode] = useState<HTMLElement | null>(null);
  const setRef = useCallback(
    (newNode: any) => {
      setNode(processNode(newNode));
    },
    [processNode],
  );
  return [node, setRef];
}

export default useStateRef;
