import React, { memo } from 'react';
import Translations from './FiltersAdvanced.translation';
import Divider from '@pedidosya/web-fenix/atoms/Divider';
import { useFiltersAdvanced } from './useFiltersAdvanced';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import type { FC } from 'react';
import type { DependenciesProps, FiltersAdvancedProps } from './FiltersAdvanced.type';
/**
 * Components
 */
import { FiltersOptions } from './FiltersOptions';
import { SectionTitle } from './FiltersAdavanced.style';
import { Container } from '@app/shared/components/Container';

export const FiltersAdvanced: FC<FiltersAdvancedProps> = WithDependencies(
  memo(({ dependencies }) => {
    const { formatMessage } = (dependencies as DependenciesProps).useIntl();
    const { filtersDate, filtersStates, onSelectState, onSelectPeriod } = useFiltersAdvanced({
      dependencies,
    });
    return (
      <>
        <SectionTitle
          token="font-subtitle-highcontrast-sentence-large"
          color="text-color-action-enabled-loud"
          component="p"
        >
          {formatMessage(Translations.period)}
        </SectionTitle>
        <FiltersOptions filters={filtersDate} onSelect={onSelectPeriod} />
        <Container pTop="8" pBottom="0">
          <Divider />
        </Container>
        <SectionTitle
          token="font-subtitle-highcontrast-sentence-large"
          color="text-color-action-enabled-loud"
          component="p"
        >
          {formatMessage(Translations.state)}
        </SectionTitle>
        <FiltersOptions filters={filtersStates} onSelect={onSelectState} />
      </>
    );
  }),
);
