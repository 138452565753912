import React, { useEffect } from 'react';
import pkg from '@root/package.json';
import { sentryHub } from '@app/shared/utils/sentry';
import { usePublicEnv, ContextType } from '@app/shared/contexts/PublicEnv';
import type { FC, ReactElement } from 'react';

type Props = {
  children: ReactElement;
};

const SentryProvider: FC<Props> = ({ children }) => {
  const { userId, environment, country, device, deviceOS } = usePublicEnv() as ContextType;

  useEffect(() => {
    try {
      sentryHub.init({ dsn: SENTRY_DSN, environment });
      sentryHub.setUser({ id: userId });
      sentryHub.setTag('country', country?.shortName);
      sentryHub.setTag('templateVersion', pkg.templateVersion);
      sentryHub.setTag('origin_env', 'frontend');
      sentryHub.setTag('device', device);
      sentryHub.setTag('deviceOS', deviceOS);
    } catch (error) {
      console.error('Error initializing sentry hub', (error as Error)?.message);
    }
  }, [environment, userId, country?.shortName, device, deviceOS]);

  return children;
};

export { SentryProvider };
