import React from 'react';
import styled from 'styled-components';
import type { TitleStyle } from '@app/orders/domain/models';

const Container = styled.div`
  text-align: center;
  width: 100%;
`;

const ImageContainer = styled.div`
  text-align: center;
  margin-bottom: 25px;
  & > picture {
    display: inline-block;
  }
`;

const TitleContainer = styled.div<{ titleStyle?: TitleStyle }>`
  margin-bottom: 12px;
  text-align: center;
  & > h3 {
    text-align: center;
    font-size: ${(props) => (props.titleStyle === 'big' ? '20px' : '16px')};
    font-weight: 500;
    line-height: ${(props) => (props.titleStyle === 'big' ? '28px' : '20px')};
    display: inline-block;
    font-style: normal;
    letter-spacing: 0.01em;
    color: #100423;
  }
`;

const DescriptionContainer = styled.div`
  margin-bottom: 24px;
  text-align: center;
  & > h3 {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    display: inline-block;
    font-style: normal;
    letter-spacing: 0.01em;
    color: #100423;
  }
`;

const CTAContainer = styled.div`
  margin-bottom: 12px;
  text-align: center;
  & > div {
    display: inline-block;
  }
`;

export { Container, TitleContainer, DescriptionContainer, ImageContainer, CTAContainer };
