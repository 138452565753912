import React, { memo } from 'react';
import RadioButton from '@pedidosya/web-fenix/atoms/RadioButton';
import type { FC } from 'react';
import type { FiltersRadioButtonProps } from './FiltersRadioButton.type';

export const FiltersRadionButton: FC<FiltersRadioButtonProps> = memo(
  ({ selected, value, onSelect }) => {
    return (
      <div>
        <RadioButton selected={selected} onClick={() => onSelect(value)} />
      </div>
    );
  },
);
