import axios from 'axios';
import { Urls } from '@app/shared/config/Urls';

const AxiosInstance = (function () {
  let instance = null;
  function Singleton() {
    if (instance) {
      return instance;
    }
    instance = this;
  }
  Singleton.getInstance = function () {
    if (!instance) {
      instance = axios.create({
        baseURL: Urls.BASE,
        timeout: 30000,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
    return instance;
  };
  return Singleton;
})();

export { AxiosInstance as HttpClient };
