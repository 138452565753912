import React, { FC } from 'react';
import { IOCContext } from './context';

type IOCDependencies<T = Object> = { [K in keyof T]: T[K] };

type IOCProviderProps = {
  children: JSX.Element | Array<JSX.Element>;
  dependencies: IOCDependencies;
};

export const IOCProvider: FC<IOCProviderProps> = ({ children, dependencies }) => {
  return <IOCContext.Provider value={dependencies}>{children}</IOCContext.Provider>;
};
