import React from 'react';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import { ListOrdersProps } from './ListOrders.type';
import type { FC } from 'react';
import type { Depedencies } from '@app/orders/domain/models/Dependencies';
/**
 * Components
 */
import { LayoutWeb } from '@app/orders/presentation/layouts/LayoutWeb';
import { LayoutMobile } from '@app/orders/presentation/layouts/LayoutMobile';

export const ListOrders: FC<ListOrdersProps> = WithDependencies(({ dependencies }) => {
  const {
    loading,
    error,
    data,
    currentData,
    hasNext,
    loadingNext,
    rowVirtualizer,
    filters,
    setStateFilter,
    countFilters,
    topRef,
    nextPage,
    origin,
    paddingStart,
    emptyStateRemedies,
    ctaAction,
    closeAction,
    isWeb,
    errorCurrent,
  } = (dependencies as Depedencies)?.useListOrders({ dependencies });

  if (isWeb) {
    return (
      <LayoutWeb
        data={data}
        rowVirtualizer={rowVirtualizer}
        hasNext={hasNext}
        nextPage={nextPage}
        loadingNext={loadingNext}
        origin={origin}
        ref={topRef}
        currentData={currentData}
        emptyStateRemedies={emptyStateRemedies}
        loading={loading}
        paddingStart={paddingStart}
        ctaAction={ctaAction}
        errorCurrent={errorCurrent}
        error={error}
      />
    );
  }

  return (
    <LayoutMobile
      data={data}
      rowVirtualizer={rowVirtualizer}
      hasNext={hasNext as boolean}
      nextPage={nextPage}
      loadingNext={loadingNext}
      currentData={currentData}
      paddingStart={paddingStart}
      emptyStateRemedies={emptyStateRemedies}
      loading={loading}
      topRef={topRef}
      closeAction={closeAction}
      ctaAction={ctaAction}
      filters={filters}
      setStateFilter={setStateFilter}
      countFilters={countFilters}
      errorCurrent={errorCurrent}
      error={error}
    />
  );
});
