import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const Container = styled.div`
  display: flex;
`;

const AmountContainer = styled(Typography)`
  margin-right: 16px;
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    top: 50%;
    right: -9.5px;
    margin-top: -1.5px;
    display: ${(props) => (props.hasProducts ? 'block' : 'none')};
    width: 3px;
    height: 3px;
    background-color: ${(props) => props.theme.color('text-color-secondary')};
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
`;

const ProductsContainer = styled(Typography)``;

export { Container, AmountContainer, ProductsContainer };
