export enum Actions {
  setFilterStates = 'setFilterStates',
  setFilterDates = 'setFilterDates',
  setOrders = 'setOrders',
  setFilterByState = 'setFilterByState',
  setFiltersByPeriod = 'setFiltersByPeriod',
  addFilterByState = 'addFilterByState',
  clearFilters = 'clearFilters',
  setFilters = 'setFilters',
  setFiltersByPeriodTmp = 'setFiltersByPeriodTmp',
  setFilterByStateTmp = 'setFilterByStateTmp',
}
