import { OrdersService as IOrdersService } from '@app/orders/domain/services/OrdersService';
import { OrdersRepository } from '@app/orders/data/repositories';
import {
  HistoryOrdersRequest,
  HistoryOrdersResponse,
  CurrentOrdersRequest,
  CurrentOrdersResponse,
} from '@app/orders/domain/models';

export class OrdersService implements IOrdersService {
  private repository: OrdersRepository;

  constructor(repository: OrdersRepository) {
    this.repository = repository;
  }
  getOrders(payload: HistoryOrdersRequest): Promise<HistoryOrdersResponse> {
    return this.repository.getOrders(payload);
  }
  getCurrentOrders(payload: CurrentOrdersRequest): Promise<CurrentOrdersResponse> {
    return this.repository.getCurrentOrders(payload);
  }
}
