export const Verticals = {
  RESTAURANT: 'RESTAURANT',
  GROCERIES: 'GROCERIES',
  PHARMACY: 'PHARMACY',
  DRINKS: 'DRINKS',
  PETS: 'PETS',
  SHOP: 'SHOP',
  COFFEE: 'COFFEE',
  FLOWERS: 'FLOWERS',
  COURIER: 'COURIER',
  CASH: 'CASH',
  COURIER_BUSINESS: 'COURIER_BUSINESS',
  KIOSKS: 'KIOSKS',
  BILLS: 'BILLS',
  UNKNOW: 'UNKNOW',
};
