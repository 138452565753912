import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const SectionTitle = styled(Typography)`
  padding-left: ${(props) => props.theme.space('space-08')};
  padding-right: ${(props) => props.theme.space('space-08')};
  margin-top: ${(props) => props.theme.space('space-12')};
  margin-bottom: ${(props) => props.theme.space('space-6')};
`;

export { SectionTitle };
