import React from 'react';
import Error from '@pedidosya/web-fenix/pages/Error';
import useIsWebView from '@app/shared/hooks/useIsWebView';
import { Container } from '@app/shared/components/Container';
import { DeepLink } from '@app/shared/utils/deeplink';
import { getWindow } from '@app/shared/utils/window';

const NotFound = () => {
  const isWebView = useIsWebView();
  const deeplink = DeepLink.home.ios;
  const wn = getWindow();
  const handleClick = () => {
    if (isWebView) {
      wn.location.href = deeplink;
    } else {
      wn.location.href = '/';
    }
  };
  return (
    <Container>
      <Error errorCode={404} primaryActionLabel="Ir al inicio" primaryActionClick={handleClick} />
    </Container>
  );
};

export { NotFound };
