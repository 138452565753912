import { Urls } from '@app/shared/config/Urls';
import { HttpClient } from '@app/shared/utils/http';
import type { MetricsRepository as IMetricsRepository } from './metricsRepository.type';

export class MetricsRepository implements IMetricsRepository {
  /**
   * sendEvent
   *
   * @description Method for call service of metrics
   * @param {string} event - Event name to increment counter in DD
   */
  public async sendEvent(event: string): Promise<string> {
    const result = await HttpClient.getInstance().post(`${Urls.METRICS}`, { event });
    return result.data;
  }
}
