import React, { FC, JSXElementConstructor } from 'react';
import { useIOC } from './context';

type HOCProps = { [key: string]: any };

export function WithDependencies<T extends HOCProps>(Component: JSXElementConstructor<T>) {
  return (props: T) => {
    const dependencies = useIOC();
    return <Component dependencies={dependencies} {...props} />;
  };
}
