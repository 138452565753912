import { InitialValue, Actions } from './context';
import { getBoundariesDatesForPeriod } from '@app/orders/use_cases/utils/date';
import { Actions as ReduceActions } from '@app/orders/data/contexts/OrdersProvider/actions';
import { State, FilterState, Filters } from '@app/orders/domain/models';

export const OrdersReducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case ReduceActions.setFilterStates:
      return {
        ...state,
        filterStates: [...action.filterStates],
        tmpFiltersState: [...action.filterStates],
      };
    case ReduceActions.setFilterDates:
      return {
        ...state,
        filterDates: [...action.filterDates],
        tmpFilterDates: [...action.filterDates],
      };
    case ReduceActions.setOrders: {
      return { ...state, orders: action.orders };
    }
    case ReduceActions.setFilterByState: {
      const filters = { ...state.filters };
      filters.state = filters.state === action.state ? '' : action.state;
      return {
        ...state,
        filters: filters,
        filtersTemp: filters,
        filterStates: state.filterStates.map((element: FilterState) => {
          return {
            ...element,
            selected: element.value === action.state && filters.state,
          } as FilterState;
        }),
        tmpFiltersState: state.filterStates.map((element: FilterState) => {
          return {
            ...element,
            selected: element.value === action.state && filters.state,
          } as FilterState;
        }),
      };
    }
    case ReduceActions.setFiltersByPeriod: {
      const filters = { ...state.filters };
      const date = getBoundariesDatesForPeriod(action.period);
      filters.dateStart = date.dateStart;
      filters.dateEnd = date.dateEnd;
      filters.period = action.period;
      return {
        ...state,
        filters: filters,
        filtersTemp: filters,
        filterDates: state.filterDates.map((element: FilterState) => {
          return { ...element, selected: element.value === action.period };
        }),
      };
    }
    case ReduceActions.addFilterByState: {
      const filters = { ...state.filters };
      filters.state = action.state;
      return { ...state, filters: filters };
    }
    case ReduceActions.clearFilters: {
      return {
        ...state,
        filters: InitialValue.filters,
        filtersTemp: InitialValue.filtersTemp,
        filterStates: state.filterStates.map((element: FilterState) => {
          return { ...element, selected: false };
        }),
        tmpFiltersState: state.filterStates.map((element: FilterState) => {
          return { ...element, selected: false };
        }),
        filterDates: state.filterDates.map((element: FilterState) => {
          return { ...element, selected: false };
        }),
        tmpFilterDates: state.filterDates.map((element: FilterState) => {
          return { ...element, selected: false };
        }),
      };
    }
    case ReduceActions.setFilters: {
      const filters = { ...state.filters };
      filters.state = action.state;
      const date = getBoundariesDatesForPeriod(action.period);
      filters.dateStart = date.dateStart;
      filters.dateEnd = date.dateEnd;
      filters.period = action.period;
      return {
        ...state,
        filters: filters,
        filterDates: state.filterDates.map((element: FilterState) => {
          return { ...element, selected: element.value === action.period };
        }),
        filterStates: state.filterStates.map((element: FilterState) => {
          return { ...element, selected: element.value === action.state };
        }),
        tmpFiltersState: state.filterStates.map((element: FilterState) => {
          return { ...element, selected: element.value === action.state };
        }),
      };
    }
    case ReduceActions.setFiltersByPeriodTmp: {
      const filters = { ...state.filtersTemp };
      const date = getBoundariesDatesForPeriod(action.period);
      filters.dateStart = date.dateStart;
      filters.dateEnd = date.dateEnd;
      filters.period = action.period;
      return {
        ...state,
        tmpFilterDates: state?.tmpFilterDates
          ? state.tmpFilterDates.map((element: FilterState) => {
              return { ...element, selected: element.value === action.period };
            })
          : [],
        filtersTemp: { ...filters } as Filters,
      };
    }
    case ReduceActions.setFilterByStateTmp: {
      const filters = { ...state.filtersTemp };
      filters.state = action.state;
      return {
        ...state,
        tmpFiltersState: state?.tmpFiltersState
          ? state.tmpFiltersState.map((element: FilterState) => {
              return { ...element, selected: element.value === action.state };
            })
          : [],
        filtersTemp: { ...filters } as Filters,
      };
    }
    default:
      throw new Error('No action available');
  }
};
