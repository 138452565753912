export const FilterDates = {
  '1w': {
    value: '1w',
    translationKey: 'period.lastWeek',
    getDate: () => {
      const today = new Date();
      const dateStart = new Date();
      dateStart.setDate(today.getDate() - 7);
      return { dateStart: dateStart.toISOString(), dateEnd: today.toISOString() };
    },
  },
  '15d': {
    value: '15d',
    translationKey: 'period.fifteenDaysAgo',
    getDate: () => {
      const today = new Date();
      const dateStart = new Date();
      dateStart.setDate(today.getDate() - 15);
      return { dateStart: dateStart.toISOString(), dateEnd: today.toISOString() };
    },
  },
  '30d': {
    value: '30d',
    translationKey: 'period.thirtyDaysAgo',
    getDate: () => {
      const today = new Date();
      const dateStart = new Date();
      dateStart.setDate(today.getDate() - 30);
      return { dateStart: dateStart.toISOString(), dateEnd: today.toISOString() };
    },
  },
  '3M': {
    value: '3M',
    translationKey: 'period.threeMonthsAgo',
    getDate: () => {
      const today = new Date();
      const dateStart = new Date();
      dateStart.setMonth(today.getMonth() - 3);
      return { dateStart: dateStart.toISOString(), dateEnd: today.toISOString() };
    },
  },
  '6M': {
    value: '6M',
    translationKey: 'period.sixMonthsAgo',
    getDate: () => {
      const today = new Date();
      const dateStart = new Date();
      dateStart.setMonth(today.getMonth() - 6);
      return { dateStart: dateStart.toISOString(), dateEnd: today.toISOString() };
    },
  },
};

export type FilterDatesType = {
  value: string;
  translationKey: string;
  getDate(): ReturnType<() => { dateStart: string; dateEnd: string }>;
};
