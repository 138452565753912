import { Constants } from '@app/shared/config/constants';
import { getItem } from '@app/shared/utils/storage';
import { AxiosInstance } from 'axios';
import { Urls } from '@app/basket/infra/config/Urls';
import { BasketRepository as IBasketRepository } from '@app/basket/domain/repositories';
import { BasketHub, BasketHubRequest } from '@app/basket/domain/models';

export class BasketRepository implements IBasketRepository {
  private http: AxiosInstance;
  constructor(http: AxiosInstance) {
    this.http = http;
  }

  public async getBasketHub(payload: BasketHubRequest): Promise<BasketHub> {
    const result = await this.http.get<BasketHub>(Urls.BASKET_HUB, {
      params: {
        mock: getItem(Constants.MOCK_FLAG, false),
        flow: payload.flow,
        lat: payload.lat,
        lng: payload.lng,
      },
      signal: payload.signal,
    });
    return result.data;
  }
}
