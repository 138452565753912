import React, { useEffect, useState, useCallback, FC } from 'react';
import PropTypes from 'prop-types';
import ArrowLeft from '@pedidosya/web-fenix/icons/ArrowLeft';
import ArrowRight from '@pedidosya/web-fenix/icons/ArrowRight';

import { Swiper as SwiperContainer, Container, ButtonContainer } from './Swiper.style';
import useStateRef from './useStateRef';

const DEFAULT_SCROLL = 300;

interface SwiperProps extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
  withScrollButtons?: boolean;
}

export const Swiper: FC<SwiperProps> = ({ children, withScrollButtons }) => {
  const [scroll, setScroll] = useState(DEFAULT_SCROLL);
  const [showButtons, setShowButtons] = useState(false);
  const [currentLeftScroll, setCurrentLeftScroll] = useState(0);
  const [showRightButton, setShowRighButton] = useState(true);

  const [parentRef, setParentRef] = useStateRef(
    (node: HTMLElement): HTMLElement | null => node || null,
  );
  const [childrenRef, setChildrenRef] = useStateRef(
    (node: HTMLElement): HTMLElement | null => node || null,
  );

  useEffect(() => {
    const scrollWidth = childrenRef?.scrollWidth || 0;
    const offsetWidth = childrenRef?.offsetWidth || 0;

    const shouldShowRightButton = scrollWidth - offsetWidth + 1 > currentLeftScroll;
    setShowRighButton(shouldShowRightButton);
  }, [
    childrenRef?.scrollWidth,
    childrenRef?.scrollLeft,
    childrenRef?.offsetWidth,
    currentLeftScroll,
  ]);

  const doScroll = useCallback(
    (scrollOffset: number) => {
      if (!childrenRef) return;

      childrenRef.scrollTo({
        top: 0,
        left: childrenRef.scrollLeft + scrollOffset,
        behavior: 'smooth',
      });

      setCurrentLeftScroll(currentLeftScroll + scrollOffset);
    },
    [childrenRef, currentLeftScroll],
  );

  useEffect(() => {
    if (parentRef && parentRef?.clientWidth > 0) setScroll(parentRef?.clientWidth);
  }, [parentRef, childrenRef]);

  const handleShowButtons = () => {
    if (!withScrollButtons) return;
    const scrollWidth = childrenRef?.scrollWidth || 0;
    const parentWidth = parentRef?.clientWidth || 0;
    if (parentWidth < scrollWidth) setShowButtons(true);
  };

  return (
    <Container
      ref={setParentRef}
      onMouseEnter={() => handleShowButtons()}
      onMouseLeave={() => setShowButtons(false)}
    >
      {showButtons && currentLeftScroll > 0 && (
        <ButtonContainer onClick={() => doScroll(-scroll)}>
          <ArrowLeft customSize={20} />
        </ButtonContainer>
      )}
      <SwiperContainer ref={setChildrenRef}>{children}</SwiperContainer>
      {showButtons && showRightButton && (
        <ButtonContainer left onClick={() => doScroll(scroll)}>
          <ArrowRight customSize={20} />
        </ButtonContainer>
      )}
    </Container>
  );
};
