import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'products.quantity': {
    id: 'OA_myOrders_card_products_quantity',
    defaultMessage: '{products, plural, =0 {0 productos} one {# producto} other {# productos}}',
  },
});

export default messages;
