import { useState, useRef, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { BasketService } from '@app/basket/use_cases/services/Basket.service';
import { BasketRepository } from '@app/basket/data/repositories/Basket.repository';
import { useUrlQuery } from '@app/shared/hooks/useUrlQuery';
import { HttpClient } from '@app/shared/utils/http';
import { BasketHub } from '@app/basket/domain/models';
import { getWindow } from '@app/shared/utils/window';
import { Constants } from '@app/shared/config/constants';

const useRecoveryCart = () => {
  const repository = new BasketRepository(HttpClient.getInstance());
  const service = new BasketService(repository);
  const query = useUrlQuery();
  const ref = useRef();
  const [countNewHub, setCountNewHub] = useState(0);
  const flow = useMemo(() => query.get(Constants.PARAM_FLOW) || '', []);
  const lat = useMemo(() => query.get(Constants.PARAM_LAT) || '', []);
  const lng = useMemo(() => query.get(Constants.PARAM_LNG) || '', []);

  const { data: dataHub } = useQuery(
    'recovery_cart_hub',
    async ({ signal }) => {
      return service.getBasketHub({
        flow: flow,
        signal: signal as AbortSignal,
        lat,
        lng,
      });
    },
    {
      retry: 2,
      onSuccess: (res: BasketHub) => {
        setCountNewHub(() => res?.quantity || 0);
      },
    },
  );
  /**
   * onClick
   *
   * @description Function to redirect
   * to recovery card depplink | weblink
   */
  const onClick = useCallback(() => {
    if (dataHub) {
      const wn = getWindow();
      wn.location.href = dataHub.deeplink;
    }
  }, [dataHub]);

  return {
    ref,
    onClick,
    countNewHub,
    dataHub,
  };
};

export { useRecoveryCart };
