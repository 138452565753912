import React, { useEffect, useState } from 'react';
import { getWindow } from '@app/shared/utils/window';

export const useScroll = () => {
  const [position, setPosition] = useState(0);
  const [down, setDown] = useState(false);
  const [up, setUp] = useState(false);

  useEffect(() => {
    const wn = getWindow();
    const updatePosition = () => {
      if (position > wn.scrollY) {
        setDown(false);
        setUp(true);
      } else {
        setDown(true);
        setUp(false);
      }
      setPosition(window.scrollY);
    };
    if (wn && (wn.document?.createElement as any)) {
      wn.addEventListener('scroll', updatePosition);
      return () => wn.removeEventListener('scroll', updatePosition);
    }
  }, [position]);

  return { position, down, up };
};
