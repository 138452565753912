import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'actions.rate': {
    id: 'OA_myOrders_card_actions_button_rate',
    defaultMessage: 'Opinar',
  },
  'actions.retry': {
    id: 'OA_myOrders_card_actions_button_repeat',
    defaultMessage: 'Repetir',
  },
  'actions.help': {
    id: 'OA_myOrders_card_actions_button_help',
    defaultMessage: 'Ayuda',
  },
});

export default messages;
