import React, { memo } from 'react';
import Translations from './OrderRating.translation';
import Rating from '@pedidosya/web-fenix/icons/RatingIcon';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { Container, StartContainer, Title } from './OrderRating.style';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import type { DependenciesProps, OrderRatingProps } from './OrderRating.type';
import type { FC } from 'react';

export const OrderRating: FC<OrderRatingProps> = WithDependencies(
  memo(({ ratingValue = 0, dependencies }) => {
    const { formatMessage } = (dependencies as DependenciesProps).useIntl();
    return (
      <Container>
        <Title
          token="font-body-midcontrast-sentence-small"
          component="p"
          color="text-color-secondary"
        >
          {formatMessage(Translations['rating.opinion'])}
        </Title>
        <StartContainer>
          <span>
            <Rating filled={true} color="icon-color-communication-rating" />
          </span>
          <Typography token="font-body-midcontrast-sentence-small" color="text-color-secondary">
            {ratingValue}
          </Typography>
        </StartContainer>
      </Container>
    );
  }),
);
