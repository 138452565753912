import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const CTAContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.color('shape-color-background-primary')};
  border-top: 1px solid #dbdade;
`;

const CTAButtonContainer = styled.div`
  padding: 16px 16px 0px 16px;
`;

const FiltersPanel = styled.div`
  position: sticky;
  top: 70px;
  right: 16px;
  padding-bottom: 16px;
`;

const MenuPanel = styled.div`
  position: sticky;
  top: 66px;
  left: 16px;
`;

const FiltersPanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  padding: 0px 16px 0px 16px;
  align-items: center;
  margin-bottom: 12px;
`;

const FiltersPanelTitle = styled(Typography)`
  flex-grow: 2;
`;

const NavPanel = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 3;
  background-color: #fff;
  border-bottom: 1px solid #c4c6cf;
  height: 56px;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 16px 0px 16px;
`;

const LoadingContainer = styled.div<{ paddingStart?: number }>`
  padding-left: 16px;
  padding-right: 16px;
`;

export {
  CTAContainer,
  CTAButtonContainer,
  FiltersPanel,
  FiltersPanelHeader,
  FiltersPanelTitle,
  MenuPanel,
  NavPanel,
  EmptyStateContainer,
  LoadingContainer,
};
