import React, { memo } from 'react';
import Translations from '@app/translations/globalTranslations';
import { DateOrder, StateOrder, StatusContainer } from './OrderStatusDate.style';
import { DateFormatter } from '@app/shared/components/DateFormatter';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import type { DependenciesProps, OrderStatusDateProps } from './OrderStatusDate.type';
import type { FC } from 'react';

export const OrderStatusDate: FC<OrderStatusDateProps> = WithDependencies(
  memo(({ date = '', label = '', colorLabel = '', dependencies }) => {
    const { formatMessage } = (dependencies as DependenciesProps).useIntl();
    return (
      <StatusContainer>
        {label && (
          <StateOrder color={colorLabel} token="font-label-highercontrast-medium">
            {formatMessage((Translations as any)[label])}
          </StateOrder>
        )}
        <DateOrder>
          <DateFormatter date={date} />
        </DateOrder>
      </StatusContainer>
    );
  }),
);
