export default {
  ChipsClick: 'pills.clicked',
  FilterPageLoad: 'my_orders_filter.loaded',
  ApplyFilters: 'my_orders_filter_apply.clicked',
  SwipedCards: 'my_orders_cards.swiped',
  RepeatClicked: 'my_orders_repeat.clicked',
  RateClicked: 'my_orders_feedback.clicked',
  HistoryLoaded: 'my_orders_history.loaded',
  BasketCouchmarkLoaded: 'basket_coachmark.loaded',
  BasketClicked: 'basket.clicked',
  HelpClicked: 'online_help.clicked',
};
