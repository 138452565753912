import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const DateFormat = styled(Typography)`
  position: relative;
`;

const MonthStyle = styled(DateFormat)`
  margin-right: 8px;
  &:after {
    content: ' ';
    position: absolute;
    top: 50%;
    right: -8px;
    margin-top: -1.5px;
    display: block;
    width: 3px;
    height: 3px;
    background-color: ${(props) => props.theme.color('text-color-secondary')};
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
`;

const HourStyle = styled(DateFormat)`
  margin-left: 4px;
`;

export { MonthStyle, HourStyle };
