import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const getColor = (color) => {
  if (color === 'pending') {
    return '#100423';
  } else if (color === 'delivered') {
    return '#4C4359';
  } else if (color === 'cancelled') {
    return '#B40443';
  }

  return '#100423';
};

const StatusContainer = styled.div`
  display: flex;
  margin-bottom: 4px;
  align-items: center;
`;

const StateOrder = styled(Typography)`
  margin-right: 12px;
  text-transform: uppercase;
  color: ${(props) => getColor(props.color)};
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    top: 50%;
    right: -8px;
    margin-top: -1.5px;
    display: block;
    width: 3px;
    height: 3px;
    background-color: ${(props) => props.theme.color('text-color-secondary')};
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
`;

const DateOrder = styled.div`
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
`;

export { StatusContainer, StateOrder, DateOrder };
