import React, { memo } from 'react';
import Translations from './OrderActions.translation';
import RatingIcon from '@pedidosya/web-fenix/icons/RatingIcon';
import Reload from '@pedidosya/web-fenix/icons/Reload';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { SideLeft, SideRight, Container } from './OrderActions.style';
import { WebViewEvents } from '@app/shared/config/webViewEvents';
import { ContextType } from '@app/shared/contexts/PublicEnv';
import { Constants } from '@app/orders/infra/config/Constants';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import type { OrderActionsProps, DependenciesProps } from './OrderActions.type';
import type { FC } from 'react';
import { OrderActionsHelpCenter } from './OrderActionsHelpCenter';

export const OrderActions: FC<OrderActionsProps> = WithDependencies(
  memo(
    ({
      repeatEnabled,
      rateEnabled,
      onRepeat,
      onRate,
      dependencies,
      businessType,
      helpCenterEnabled = false,
      orderCreated = '',
      orderId,
      showActions,
      status = '',
    }) => {
      const { formatMessage } = (dependencies as DependenciesProps).useIntl();
      const { emitEvent } = (dependencies as DependenciesProps).useLinkContext();
      const { fireBottomNavEvents } = (
        dependencies as DependenciesProps
      ).usePublicEnv() as ContextType;
      const query = (dependencies as DependenciesProps).useUrlQuery();

      const repeatAction = () => {
        if (repeatEnabled && onRepeat) {
          onRepeat();
        }
      };

      const rateAction = () => {
        if (rateEnabled && onRate) {
          if (query.get(Constants.BOTTOM_NAV_QUERY_PARAM) === 'true' && fireBottomNavEvents) {
            emitEvent(WebViewEvents.HIDE_BOTTOM_NAV, {});
          }
          onRate();
        }
      };
      if (helpCenterEnabled) {
        return (
          <OrderActionsHelpCenter
            orderId={orderId}
            onRate={onRate}
            onRepeat={onRepeat}
            rateEnabled={rateEnabled}
            repeatEnabled={repeatEnabled}
            businessType={businessType}
            orderCreated={orderCreated}
            helpCenterEnabled={helpCenterEnabled}
            showActions={showActions}
            status={status}
          />
        );
      }

      return (
        <Container>
          <SideLeft enabled={rateEnabled} role="button" tabIndex={-1} onClick={rateAction}>
            <span>
              <RatingIcon
                size="small"
                color={
                  rateEnabled
                    ? 'shape-color-surface-secondary'
                    : 'text-color-action-disabled-default'
                }
              />
            </span>
            <Typography token="font-label-highercontrast-underline-medium">
              {formatMessage(Translations['actions.rate'])}
            </Typography>
          </SideLeft>
          <SideRight enabled={repeatEnabled} role="button" tabIndex={0} onClick={repeatAction}>
            <span>
              <Reload
                size="small"
                color={
                  repeatEnabled
                    ? 'shape-color-surface-secondary'
                    : 'text-color-action-disabled-default'
                }
              />
            </span>
            <Typography token="font-label-highercontrast-underline-medium">
              {formatMessage(Translations['actions.retry'])}
            </Typography>
          </SideRight>
        </Container>
      );
    },
  ),
);
