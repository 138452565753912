import Engine from 'rules-js';
import { EmptyStateRule } from './flows/empty_state';
import { checkOption, getTranslation } from './closure/empty_state';

const RuleEngine = new Engine();
RuleEngine.closures.add('checkOption', checkOption, { required: ['vertical', 'reason'] });
RuleEngine.closures.add('getTranslation', getTranslation, {
  required: ['mainText', 'firstButton', 'secondButton'],
});

RuleEngine.add(EmptyStateRule);

/**
 * EmptyStateRule
 *
 * @description Function for process the params applying a rule for reasons
 * @param {Object} params - Parameters to evaluate
 * @param {number} params.orders - Number of orders
 * @param {number} params.currentOrders - Number of current orders
 * @param {Object} params.filter - Filters
 * @param {string} params.filter.orderState - Filter state
 * @param {string} params.filter.dateEnd - Filter date to
 * @param {string} params.filter.dateStart - Filter date from
 * @returns {Promise<Object>}
 */
export const EmptyStateRules = async (params) => {
  const result = await RuleEngine.process('empty_state', params);
  return result.fact.translations || null;
};
