import React, { createContext, useContext, Dispatch } from 'react';
import { State, FilterState, Order } from '@app/orders/domain/models';
import { Actions as ActionsTypes } from './actions';

export type Actions =
  | { type: ActionsTypes.setFilterStates; filterStates: Array<FilterState> }
  | { type: ActionsTypes.setFilterDates; filterDates: Array<FilterState> }
  | { type: ActionsTypes.setOrders; orders: Array<Order> }
  | { type: ActionsTypes.setFilterByState; state: string }
  | { type: ActionsTypes.setFiltersByPeriod; period: string }
  | { type: ActionsTypes.addFilterByState; state: string }
  | { type: ActionsTypes.clearFilters }
  | { type: ActionsTypes.setFilters; state: string; period: string }
  | { type: ActionsTypes.setFiltersByPeriodTmp; period: string }
  | { type: ActionsTypes.setFilterByStateTmp; state: string };

export type Context = {
  data: State;
  dispatch: Dispatch<Actions>;
  countFilters: number;
  countFiltersTmp: number;
  isWeb: boolean;
};

const InitialValue = {
  orders: [],
  filters: {
    state: '',
    dateStart: '',
    dateEnd: '',
    period: '',
  },
  filterStates: [],
  filterDates: [],
  filtersTemp: {
    state: '',
    dateStart: '',
    dateEnd: '',
    period: '',
  },
};
const OrdersContext = createContext<Context>({
  data: InitialValue,
  dispatch: () => {},
  countFilters: 0,
  countFiltersTmp: 0,
  isWeb: false,
});

const useOrdersContext = () => {
  return useContext(OrdersContext);
};

export { OrdersContext, useOrdersContext, InitialValue };
