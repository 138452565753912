import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'general.status.pending': {
    id: 'OA_myOrders_state_pending',
    defaultMessage: 'En curso',
  },
  'general.status.cancelled': {
    id: 'OA_myOrders_state_cancelled',
    defaultMessage: 'Cancelados',
  },
  'general.status.delivered': {
    id: 'OA_myOrders_state_delivered',
    defaultMessage: 'Entregados',
  },
  'emptyState.noOrders.title': {
    id: 'OA_myOrder_fullScreen_emptyState_title_noOrders',
    defaultMessage: 'Aún no has realizado pedidos',
  },
  'emptyState.noOrders.description': {
    id: 'OA_myOrder_fullSCreen_emptyState_description_noOrders',
    defaultMessage: 'Busca entre todas nuestras opciones y disfruta de tu primer pedido.',
  },
  'emptyState.noOrders.makeOrder': {
    id: 'OA_myOrder_fullSCreen_emptyState_button_makeOrder',
    defaultMessage: 'Hacer pedido',
  },
  'navbar.title': {
    id: 'OA_myOrder_navbar_title',
    defaultMessage: 'Mis pedidos',
  },
  'month.format': {
    id: 'OA_myOrders_date_month_format',
    defaultMessage: '{day} de {month}',
  },
  'hour.format': {
    id: 'OA_myOrders_date_hours_format',
    defaultMessage: '{hour}:{minute} hs',
  },
  'card.state.pending': {
    id: 'OA_myOrders_card_state_pending',
    defaultMessage: 'En curso',
  },
  'card.state.delivered': {
    id: 'OA_myOrders_card_state_delivered',
    defaultMessage: 'Entregado',
  },
  'card.state.cancelled': {
    id: 'OA_myOrders_card_state_cancelled',
    defaultMessage: 'Cancelado',
  },
  'card.openTime.label': {
    id: 'OA_myOrders_card_openTime_label',
    defaultMessage: 'Abre a las {openTime}',
  },
  'card.openTime.closed': {
    id: 'OA_myOrders_card_openTime_label_closed',
    defaultMessage: 'Cerrado por hoy',
  },
  'period.lastWeek': {
    id: 'OA_myOrders_filter_page_period_lastWeek',
    defaultMessage: 'Última semana',
  },
  'period.fifteenDaysAgo': {
    id: 'OA_myOrders_filter_page_period_fifteenDaysAgo',
    defaultMessage: 'Últimos 15 días',
  },
  'period.thirtyDaysAgo': {
    id: 'OA_myOrders_filter_page_period_thirtyDaysAgo',
    defaultMessage: 'Últimos 30 días',
  },
  'period.threeMonthsAgo': {
    id: 'OA_myOrders_filter_page_period_threeMonthAgo',
    defaultMessage: 'Últimos 3 meses',
  },
  'period.sixMonthsAgo': {
    id: 'OA_myOrders_filter_page_period_sixMonthAgo',
    defaultMessage: 'Últimos 6 meses',
  },
  'emptyState.noOrders.withCurrent.title': {
    id: 'OA_myOrder_fullScreen_emptyState_title_noOrders_withCurrent',
    defaultMessage: 'Tu primer pedido está en curso',
  },
  'emptyState.noOrders.withCurrent.description': {
    id: 'OA_myOrder_fullSCreen_emptyState_description_noOrders_withCurrent',
    defaultMessage: 'Lo verás aquí cuando sea entregado.',
  },
  'emptyState.noOrders.withCurrent.cancelled.title': {
    id: 'OA_myOrder_fullScreen_emptyState_title_noOrders_withCurrent_cancelled',
    defaultMessage: '¡Todo viene bien!',
  },
  'emptyState.noOrders.withCurrent.cancelled.description': {
    id: 'OA_myOrder_fullSCreen_emptyState_description_noOrders_withCurrent_cancelled',
    defaultMessage: 'No tienes pedidos cancelados.',
  },
  'emptyState.noOrders.withCurrent.filtered_empty.title': {
    id: 'OA_myOrder_fullScreen_emptyState_title_noOrders_withCurrent_filtered_empty',
    defaultMessage: 'No encontramos pedidos para estos filtros',
  },
  'emptyState.noOrders.withCurrent.filtered_empty.description': {
    id: 'OA_myOrder_fullSCreen_emptyState_description_noOrders_withCurrent_filtered_empty',
    defaultMessage: 'Por favor, limpialos y probá con otros.',
  },
  'emptyState.noOrders.withCurrent.button.clearFilters': {
    id: 'OA_myOrder_fullSCreen_emptyState_noOrders_withCurrent_button_clearFilters',
    defaultMessage: 'Limpiar filtros',
  },
  titleError: {
    id: 'OA_myOrder_fullSCreen_error_global_title',
    defaultMessage: 'Tuvimos un problema',
  },
  subTitleError: {
    id: 'OA_myOrder_fullSCreen_error_global_description',
    defaultMessage: 'Parece que no podemos atender tu solicitud en este momento.',
  },
  buttonLabelError: {
    id: 'OA_myOrder_fullSCreen_error_global_button_cta',
    defaultMessage: 'Ir al inicio',
  },
  'filters_advanced.apply.button': {
    id: 'OA_myOrders_filter_page_apply',
    defaultMessage: 'Aplicar',
  },
  'filters_advanced.title': {
    id: 'OA_myOrders_filter_page_title',
    defaultMessage: 'Filtrar por',
  },
  'filters_advanced.clear': {
    id: 'OA_myOrders_filter_page_clear',
    defaultMessage: 'Limpiar filtros',
  },
  products_quantity: {
    id: 'OA_myOrders_card_products_quantity',
    defaultMessage: '{products, plural, =0 {0 productos} one {# producto} other {# productos}}',
  },
  'vendorDetail.deliveryTime': {
    id: 'OA_myOrders_vndorDetail_delivery_time',
    defaultMessage: '{min} - {max} min',
  },
  'repeatOrder.outofzone.title': {
    id: 'OA_myOrders_repeatOrder_modal_title',
    defaultMessage: 'Fuera de la zona de cobertura',
  },
  'repeatOrder.outofzone.description': {
    id: 'OA_myOrders_repeatOrder_modal_description',
    defaultMessage: 'Esta dirección está fuera del rango posible de entrega',
  },
  'repeatOrder.general_error.modal.title': {
    id: 'OA_myOrders_repeatOrder_error_modal_title',
    defaultMessage: 'Problemas al repetir tu pedido',
  },
  'repeatOrder.general_error.modal..description': {
    id: 'OA_myOrders_repeatOrder_error_modal_description',
    defaultMessage:
      'En estos momentos estamos teniendo problemas para repetir tu pedido, vuelve a intentarlo mas tarde',
  },
  'repeatOrder.fullPage.selectAddress': {
    id: 'OA_myOrders_repeatOrder_fullPage_selectAddress',
    defaultMessage: 'Selecciona una de tus direcciones',
  },
  'repeatOrder.vendorDetail.product.title': {
    id: 'OA_myOrders_repeatOrder_vendorDetail_product',
    defaultMessage: 'Productos',
  },
  'general.label.ok': {
    id: 'OA_myOrders_general_label_ok',
    defaultMessage: 'Ok',
  },
  'couchmark.title': {
    id: 'OA_myOrders_couchmark_title',
    defaultMessage: '¡Retoma tu carrito!',
  },
  'couchmark.message': {
    id: 'OA_myOrders_couchmark_message',
    defaultMessage: 'Tu pedido de {vendorName} te espera.',
  },
  'filters.title': {
    id: 'OA_myOrders_filters_title',
    defaultMessage: 'Filtros',
  },
});

export default messages;
