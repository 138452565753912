import React, { memo } from 'react';
import Cart from '@pedidosya/web-fenix/icons/Cart';
import { ContainerHub, Badge } from './RecoveryCart.style';
import { useRecoveryCart } from './useRecoveryCart';

export const RecoveryCart = memo(() => {
  const { ref, onClick, countNewHub, dataHub } = useRecoveryCart();

  return (
    <>
      <ContainerHub ref={ref} onClick={onClick}>
        <Cart size="medium" color="shape-color-surface-secondary" />
        {dataHub && countNewHub > 0 ? (
          <Badge
            token="font-label-highercontrast-small"
            color="text-color-inverted"
            data-testid="badge"
          >
            {countNewHub}
          </Badge>
        ) : (
          <></>
        )}
      </ContainerHub>
    </>
  );
});
