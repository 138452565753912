/**
 * AuthProvider
 *
 * @descrition Provider for check if a user is logged, otherwise
 * will redirect to login page (only applied for web version)
 */

import React, { useEffect, useState, useCallback } from 'react';
import type { FC } from 'react';
import { usePublicEnv } from '@app/shared/contexts/PublicEnv';
import { ExternalRepository } from '@app/shared/repositories/external';
import { Constants } from '@app/shared/config/constants';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import useIsWebView from '@app/shared/hooks/useIsWebView';

type Props = {
  children: React.ReactNode;
};

const AuthProvider: FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const env = usePublicEnv();
  const isWebView = useIsWebView();
  const service = new ExternalRepository();

  const urlRedirect = useCallback(async () => {
    try {
      const result = await service.getRedirectLogin();
      window.location.href = `${result?.redirectUrl}&${Constants.ORIGIN_PATH}=${Constants.MY_ORDERS_PATH}`;
    } catch (e) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (env && !isWebView) {
      if (!env?.userId) {
        urlRedirect();
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [env, isWebView, urlRedirect]);

  if (loading && !isWebView) {
    return <PeyaLoader position="center" />;
  }

  return <>{children}</>;
};

export { AuthProvider };
