import React, { memo } from 'react';
import { default as Ch } from '@pedidosya/web-fenix/atoms/Chip';
import type { FC } from 'react';

type Props = {
  label: string;
  value: unknown;
  onSelect(value: unknown): void;
  selected: boolean;
};

export const Chip: FC<Props> = memo(({ label, value, onSelect, selected }) => {
  const clickHandler = () => {
    if (onSelect) {
      onSelect(value);
    }
  };
  return (
    <Ch
      label={label}
      state={selected ? 'pressed' : 'enabled'}
      onClick={clickHandler}
      type="action"
    />
  );
});
