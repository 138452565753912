import styled from 'styled-components';

const Container = styled.div<{ ref?: any; borderB?: boolean }>`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 100px;
  z-index: 2;
  background-color: ${(props) => props.theme.color('shape-color-surface-primary')};
  box-shadow: ${(props) => (props.borderB ? '0px 4px 8px rgba(16, 4, 35, 0.12);' : 'none')};
`;

export { Container };
