import React, { createContext, useContext } from 'react';

export type Tracker = {
  track(name: string, param: any): void;
};

export type ContextType = {
  tracker: Tracker | null;
  gtmTrack?(name: string, param: any): void;
  chipClicked(origin: string, filterValue: string): void;
  filterPageLoaded(origin: string): void;
  applyFilter(origin: string, filterStateValue: string, filterPeriodValue: string): void;
  swipedCards(origin: string): void;
  repeatClicked(origin: string, orderId: string, position: number): void;
  rateClicked(origin: string, orderId: string, position: number): void;
  historyLoaded(origin: string, currentOrders: number, orders: number): void;
  basketCouchmarkLoaded(origin: string, basketId: string, vendorId: number): void;
  basketClicked(origin: string, basketId: string, vendorId: number): void;
  helpClicked(origin: string, orderId: string, orderState: string): void;
};

export const TrackerContext = createContext<ContextType>({ tracker: null } as ContextType);

export const useTracker = () => {
  return useContext(TrackerContext);
};
