import React, { MutableRefObject } from 'react';
import styled from 'styled-components';

const Card = styled.div<{ ref?: any }>`
  border: 1px solid #dbdade;
  box-sizing: border-box;
  padding: 12px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  min-width: 300px;
  margin-bottom: 16px;
`;

const CardContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
`;

const ImageContainer = styled.div`
  margin-right: 12px;
  align-self: start;
`;

const ContentContainer = styled.div`
  align-self: start;
  flex: 1 1;
`;

const ActionsContainer = styled.div`
  width: 100%;
  margin-top: 12px;
  border-top: 1px solid #dbdade;
  display: flex;
  justify-content: space-around;
  padding-top: 16px;
`;

const Side = styled.div`
  width: 50%;
  text-align: center;
  position: relative;
  &:first-child {
    &:after {
      content: ' ';
      position: absolute;
      width: 1px;
      top: 0px;
      right: 0px;
      background-color: #dbdade;
      height: 100%;
    }
  }
`;

export { Card, ImageContainer, CardContainer, ContentContainer, ActionsContainer, Side };
