export const FilterStates = [
  {
    value: 'DELIVERED',
    translationKey: 'general.status.delivered',
  },
  {
    value: 'REJECTED',
    translationKey: 'general.status.cancelled',
  },
];
