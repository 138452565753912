import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Fallback from '@app/assets/images/fallback_partner.png';
import { Shimmer } from '@app/shared/components/Shimmer';
import { ImageContainer } from './ImageLoader.style';

const ImageLoader = ({ src, width = 40, height = 40 }) => {
  const [source, setSource] = useState('');

  useEffect(() => {
    let isMounted = true;
    const image = new Image();
    image.src = src;
    image.onload = () => {
      if (isMounted) {
        setSource(src);
      }
    };
    image.onerror = () => {
      setSource(Fallback);
    };
    return () => {
      isMounted = false;
    };
  }, [src]);

  if (!source) {
    return <Shimmer width={`${width}px`} height={`${height}px`} shape="square" />;
  }

  return (
    <ImageContainer
      width={width}
      height={width}
      data-testid="image"
      src={source}
      role="img"
      aria-label="Partner image"
    />
  );
};

ImageLoader.defaultProps = {
  src: '',
  width: 40,
  height: 40,
};

ImageLoader.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export { ImageLoader };
