import React from 'react';
import styled from 'styled-components';

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 16px 16px 16px;
`;

const FilterIconContainer = styled.div`
  margin-right: 8px;
  position: relative;
  display: flex;
  align-items: center;
  & > svg {
    margin-right: 8px;
  }
  & > div {
    margin-right: 8px;
  }
`;
const TagContainer = styled.div`
  margin-left: 8px;
  &:first-child {
    margin-left: 0px;
  }
`;

const SwiperSection = styled.div`
  overflow: hidden;
`;

export { TagContainer, FiltersContainer, FilterIconContainer, SwiperSection };
