import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'OA_myOrders_filter_page_title',
    defaultMessage: 'Filtrar por',
  },
  clear: {
    id: 'OA_myOrders_filter_page_clear',
    defaultMessage: 'Limpiar filtros',
  },
  period: {
    id: 'OA_myOrders_filter_page_period',
    defaultMessage: 'Período',
  },
  state: {
    id: 'OA_myOrders_filter_page_state',
    defaultMessage: 'Estado',
  },
  apply: {
    id: 'OA_myOrders_filter_page_apply',
    defaultMessage: 'Aplicar',
  },
});

export default messages;
