import styled from 'styled-components';
import type { forwardRef, HTMLAttributes, Ref, Dispatch, SetStateAction } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  ref: any;
}

export const ButtonContainer = styled.div<{ left?: boolean }>`
  position: absolute;
  display: flex;
  height: 44px;
  width: 44px;
  justify-content: center;
  align-items: center;
  ${(props) => (props.left ? `right: 0` : `left: 0`)};
  z-index: 1;
  border-radius: 50%;
  background: ${({ theme }) => theme.color('shape-color-surface-primary')};
  padding: 3px;
  cursor: pointer;
  box-shadow: ${({ theme }) => `0 -6px 12px 0 ${theme.color('text-color-action-activated-quiet')}`};
`;

export const Container = styled.div<Props>`
  position: relative;
  display: flex;
  align-items: center;
`;

export const Swiper = styled.div<Props>`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
`;
