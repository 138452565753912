import indexOf from 'lodash/indexOf';
import { FilterStates } from '@app/orders/infra/config/FilterStates';
/**
 * checkOption
 *
 * @description Function to check options passed to process with rules
 * @param {Object} fact - Paramters to process
 * @param {Object} context - Context of engine
 */
export const checkOption = (fact, context) => {
  /**
   * Orders == 0
   * Current orders == 0
   */
  if (
    context.parameters.orders === 0 &&
    fact.orders === context.parameters.orders &&
    context.parameters.currentOrders === 0 &&
    fact.currentOrders === context.parameters.currentOrders
  ) {
    if (
      context.parameters.filter.orderState.length === 0 &&
      fact.filter.orderState === '' &&
      context.parameters.filter.dateEnd === fact.filter.dateEnd &&
      context.parameters.filter.dateStart === fact.filter.dateStart
    ) {
      return fact;
    } else if (
      indexOf(context.parameters.filter.orderState || [], fact.filter.orderState) !== -1 &&
      context.parameters.filter.dateEnd === '!null' &&
      fact.filter.dateEnd &&
      context.parameters.filter.dateStart === '!null' &&
      fact.filter.dateStart
    ) {
      return fact;
    } else if (
      indexOf(context.parameters.filter.orderState || [], fact.filter.orderState) !== -1 &&
      context.parameters.filter.dateEnd === fact.filter.dateEnd &&
      context.parameters.filter.dateStart === fact.filter.dateStart
    ) {
      return fact;
    } else if (
      indexOf(context.parameters.filter.orderState || [], fact.filter.orderState) === -1 &&
      context.parameters.filter.dateEnd === '!null' &&
      fact.filter.dateEnd &&
      context.parameters.filter.dateStart === '!null' &&
      fact.filter.dateStart
    ) {
      return fact;
    }
    /**
     * Orders == 0
     * Current orders > 0
     */
  } else if (
    context.parameters.orders === 0 &&
    fact.orders === context.parameters.orders &&
    context.parameters.currentOrders &&
    fact.currentOrders > 0
  ) {
    if (
      context.parameters.filter.orderState.length === 0 &&
      fact.filter.orderState === '' &&
      context.parameters.filter.dateEnd === fact.filter.dateEnd &&
      context.parameters.filter.dateStart === fact.filter.dateStart
    ) {
      return fact;
    } else if (
      indexOf(context.parameters.filter.orderState || [], fact.filter.orderState) !== -1 &&
      indexOf(context.parameters.filter.except || [], fact.filter.except) === -1 &&
      context.parameters.filter.dateEnd === fact.filter.dateEnd &&
      context.parameters.filter.dateStart === fact.filter.dateStart
    ) {
      return fact;
    } else if (
      indexOf(context.parameters.filter.orderState || [], fact.filter.orderState) !== -1 &&
      context.parameters.filter.dateEnd === '!null' &&
      fact.filter.dateEnd &&
      context.parameters.filter.dateStart === '!null' &&
      fact.filter.dateStart
    ) {
      return fact;
    }
  }

  return null;
};

/**
 * getTranslation
 *
 * @description Function to return the translations keys from rule
 * @param {Object} fact - Paramters to process
 * @param {Object} context - Context of engine
 */
export const getTranslation = (fact, context) => {
  fact.translations = { ...context.parameters };
  return fact;
};
