import React, { useRef, useLayoutEffect, useState } from 'react';
import {
  Card,
  ImageContainer,
  CardContainer,
  ContentContainer,
  ActionsContainer,
  Side,
} from './CardSekeleton.style';
import { Shimmer } from '@app/shared/components/Shimmer';

export const CardSkeleton = () => {
  const cardRef = useRef<HTMLDivElement>();
  const [firstItem, setFirstItem] = useState(0);
  const [secondtItem, setSecondItem] = useState(0);
  const [thirdtItem, setThirdItem] = useState(0);

  useLayoutEffect(() => {
    const widthTotal = cardRef.current ? cardRef.current.clientWidth : 0;
    setFirstItem(widthTotal * 0.7);
    setSecondItem(widthTotal * 0.5);
    setThirdItem(widthTotal * 0.6);
  }, []);
  return (
    <Card ref={cardRef} data-testid="cardskeleton">
      <CardContainer>
        <ImageContainer>
          <Shimmer width="48px" height="48px" shape="square" />
        </ImageContainer>
        <ContentContainer>
          <Shimmer width={`${firstItem}px`} height="14px" shape="square" />
          <Shimmer width={`${secondtItem}px`} height="20px" shape="square" />
          <Shimmer width={`${thirdtItem}px`} height="14px" shape="square" />
        </ContentContainer>
        <ActionsContainer>
          <Side>
            <Shimmer width="87px" height="14px" shape="square" />
          </Side>
          <Side>
            <Shimmer width="87px" height="14px" shape="square" />
          </Side>
        </ActionsContainer>
      </CardContainer>
    </Card>
  );
};
