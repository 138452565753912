import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const CleanFilters = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CleanFiltersTitle = styled(Typography)`
  margin-left: 4px;
`;

export { CleanFilters, CleanFiltersTitle };
