import React, { memo } from 'react';
import ArrowLeft from '@pedidosya/web-fenix/icons/ArrowLeft';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { RecoveryCart } from '@app/basket/presentation/components/RecoveryCart';
import { InsideContainer, LeftSide, RightSide } from './AndroidBar.style';
import type { FC } from 'react';
import type { AndroidBarProps } from './AndroidBar.type';

export const AndroidBar: FC<AndroidBarProps> = ({ showIconBar, title, showCart, callback }) => {
  return (
    <InsideContainer>
      <LeftSide>
        {showIconBar && (
          <span data-testid="icon_back" onClick={callback} role="button" tabIndex={0}>
            <ArrowLeft size="medium" />
          </span>
        )}
        <Typography
          token="font-body-midcontrast-sentence-large"
          component="h3"
          color="text-color-primary"
        >
          {title}
        </Typography>
      </LeftSide>
      <RightSide>
        {showCart && (
          <div>
            <RecoveryCart />
          </div>
        )}
      </RightSide>
    </InsideContainer>
  );
};
