import React, { memo, forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { usePublicEnv } from '@app/shared/contexts/PublicEnv';
import { DEVICESOS } from '@app/shared/config/device';
import { useNavBar } from './useNavBar';
import type { FC } from 'react';
import type { NavBarProps } from './NavBar.type';
import type { ContextType } from '@app/shared/contexts/PublicEnv';
import GlobalTranslation from '@app/translations/globalTranslations';

/**
 * Styled
 */
import { NavContainer, MockContainer } from './NavBar.style';
/**
 * Components
 */
import { MockSwitch } from '@app/shared/components/MockSwitch';
import { AndroidBar } from './AndroidBar';
import { IOSBar } from './IOSBar';

export const NavBar = memo(
  forwardRef<HTMLElement, NavBarProps>(({ callback }, ref) => {
    const { formatMessage } = useIntl();
    const { deviceOS, mock } = usePublicEnv() as ContextType;
    const { showIconBar, isWebView } = useNavBar();
    const device = !isWebView ? DEVICESOS.WEB : deviceOS;

    return (
      <NavContainer ref={ref}>
        {DEVICESOS.ANDROID !== device && (
          <IOSBar
            title={formatMessage((GlobalTranslation as any)['navbar.title'])}
            showCart={isWebView}
            showIconBar={showIconBar}
            callback={callback}
            isWeb={DEVICESOS.ANDROID !== deviceOS && DEVICESOS.IOS !== deviceOS}
          />
        )}
        {DEVICESOS.ANDROID === device && (
          <AndroidBar
            title={formatMessage((GlobalTranslation as any)['navbar.title'])}
            showCart={isWebView}
            showIconBar={showIconBar}
            callback={callback}
          />
        )}
        {mock && (
          <MockContainer>
            <MockSwitch />
          </MockContainer>
        )}
      </NavContainer>
    );
  }),
);
