/**
 * WebViewEvents
 *
 * @description const with events to send to webviews
 */
export const WebViewEvents = {
  HOME: 'WEBVIEW_HOME',
  CLOSE: 'WEBVIEW_CLOSE',
  HIDE_BOTTOM_NAV: 'HIDE_BOTTOM_NAV',
  SHOW_BOTTOM_NAV: 'SHOW_BOTTOM_NAV',
};
