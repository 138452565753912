import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'rating.opinion': {
    id: 'OA_myOrders_card_rate_opinion',
    defaultMessage: 'Tu opinión',
  },
});

export default messages;
