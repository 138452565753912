import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const Container = styled.div<{ height: number }>`
  position: fixed;
  z-index: 3;
  background-color: ${(props) => props.theme.color('shape-color-background-primary')};
  overflow: auto;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`;

const InsideContainer = styled.div<{ ref?: any }>`
  min-height: ${(props) => `${props.height}px`};
`;

const Headers = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => props.theme.space('space-07')};
  padding: 0px 16px; 0px 16px;
  align-items: center;
  margin-bottom: ${(props) => props.theme.space('space-16')};
`;

const CloseContainer = styled.div`
  position: relative;
  height: ${(props) => props.theme.space('space-08')};
  padding: 0px 16px; 0px 16px;
  margin-top: ${(props) => props.theme.space('space-12')};
  margin-bottom: ${(props) => props.theme.space('space-12')};
`;

const Title = styled(Typography)`
  flex-grow: 2;
`;

const SectionTitle = styled(Typography)`
  padding-left: ${(props) => props.theme.space('space-08')};
  padding-right: ${(props) => props.theme.space('space-08')};
  margin-top: ${(props) => props.theme.space('space-12')};
  margin-bottom: ${(props) => props.theme.space('space-6')};
`;

const CTAContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 80px;
  background-color: ${(props) => props.theme.color('shape-color-background-primary')};
  z-index: 3;
  box-shadow: 0px -4px 8px rgba(16, 4, 35, 0.12);
`;

const CTAButtonContainer = styled.div`
  padding: 16px 16px 0px 16px;
`;

export {
  Container,
  Headers,
  Title,
  CloseContainer,
  SectionTitle,
  CTAContainer,
  CTAButtonContainer,
  InsideContainer,
};
