import React from 'react';
import styled from 'styled-components';

const getBackgroundColor = (type: string) => {
  if (type === 'invert') {
    return '#100423';
  }
  return '#ea044e';
};

const getColor = (type: string) => {
  if (type === 'invert') {
    return '#fff';
  }
  return '#fff';
};

const BadgeContainer = styled.div<{ type: string }>`
  width: 16px;
  height: 16px;
  background-color: ${(props) => getBackgroundColor(props.type)};
  color: ${(props) => getColor(props.type)};
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  padding: 0px, 3px, 0px, 3px;
`;

export { BadgeContainer };
