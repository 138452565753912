import { BasketHubRequest, BasketHub } from '@app/basket/domain/models';
import { BasketService as IBasketService } from '@app/basket/domain/services/BasketService';
import { BasketRepository } from '@app/basket/data/repositories/Basket.repository';

export class BasketService implements IBasketService {
  private repository: BasketRepository;

  constructor(repository: BasketRepository) {
    this.repository = repository;
  }
  getBasketHub(payload: BasketHubRequest): Promise<BasketHub> {
    return this.repository.getBasketHub(payload);
  }
}
