import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

const Title = styled(Typography)`
  margin: 0px;
  padding: 0px;
`;

const StartContainer = styled.div`
  display: flex;
  align-items: center;
  & > span:first-child {
    width: 13px;
    height: 13px;
    margin-right: 4px;
    margin-left: 4px;
    & > svg {
      width: 13px;
      height: 13px;
    }
  }
  & > span {
    display: flex;
  }
`;

export { Container, StartContainer, Title };
