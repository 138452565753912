import React, { memo, useMemo, useCallback, useState, useEffect } from 'react';
import { TrackerContext } from './context';
import { usePublicEnv, ContextType } from '@app/shared/contexts/PublicEnv';
import { useIntl } from 'react-intl';
import { FilterStates } from '@app/orders/infra/config/FilterStates';
import { initialize as initializeTracker, track } from '@pedidosya/web-native-bridges/tracker';
import type { FC, ReactNode } from 'react';
import isEmpty from 'lodash/isEmpty';
import Translations from '@app/translations/globalTranslations';
import GTMEvents from '@app/shared/config/gtmEvents';
import find from 'lodash/find';

type Props = {
  children: ReactNode;
  config?: any;
  tracker: { track(name: string, param: any): void } | null;
};

const TrackerProvider: FC<Props> = memo(function TrackerProvider({ children, tracker, config }) {
  const [trackerInstance, setTracker] = useState(tracker);
  const { userId } = usePublicEnv() as ContextType;
  const { formatMessage } = useIntl();

  const loadTracker = useCallback(async () => {
    try {
      await initializeTracker(config);
      setTracker({ track });
    } catch (e) {
      return null;
    }
  }, [config]);

  useEffect(() => {
    if (!isEmpty(trackerInstance)) {
      return;
    }

    loadTracker();
  }, [trackerInstance, loadTracker]);
  /**
   * chipClicked
   *
   * @description Function to fire an event to gtm to track
   * when user mark chip like filter active
   * @param {string} origin - Origin request
   * @param {string} filterValue - Filter value
   */
  const chipClicked = (origin: string, filterValue: string) => {
    const pill = find(FilterStates, (element) => element.value === filterValue);
    trackerInstance?.track(GTMEvents.ChipsClick, {
      userId,
      origin,
      pillName: pill ? formatMessage((Translations as any)[pill.translationKey]) : '',
    });
  };
  /**
   * filterPageLoaded
   *
   * @description Function to fire an event to gtm to track
   * when user open filter page
   * @param {string} origin - Origin request
   */
  const filterPageLoaded = (origin: string) => {
    trackerInstance?.track(GTMEvents.FilterPageLoad, {
      userId,
      origin,
    });
  };
  /**
   * applyFilter
   *
   * @description Function to fire event to gtm to track
   * when user make select some filter a apply them
   * @param {string} origin - Origin request
   * @param {stirng} filterStateValue - Filter state value
   * @param {string} filterPeriodValue - Filter perdiod value
   */
  const applyFilter = (origin: string, filterStateValue: string, filterPeriodValue: string) => {
    const values = {
      origin,
      userId,
    };

    if (filterPeriodValue) {
      (values as any)['filterPeriod'] = filterPeriodValue;
    }

    if (filterStateValue) {
      const pill = find(FilterStates, (element) => element.value === filterStateValue);
      (values as any)['filterState'] = pill
        ? formatMessage((Translations as any)[pill.translationKey])
        : '';
    }
    trackerInstance?.track(GTMEvents.ApplyFilters, values);
  };
  /**
   * repeatClicked
   *
   * @description Function to fire event to gtm to track
   * when user make click over repeat order button
   * @param {string} origin - Origin request
   * @param {string} orderId - Order id
   * @param {string} position - Position or card
   */
  const repeatClicked = (origin: string, orderId: string, position: number) => {
    trackerInstance?.track(GTMEvents.RepeatClicked, {
      origin,
      userId,
      orderId,
      position,
    });
  };
  /**
   * rateClicked
   *
   * @description Function to fire event to gtm to track
   * when user make click over repeat order button
   * @param {string} origin - Origin request
   * @param {string} orderId - Order id
   * @param {string} position - Position or card
   */
  const rateClicked = (origin: string, orderId: string, position: number) => {
    trackerInstance?.track(GTMEvents.RateClicked, {
      origin,
      userId,
      orderId,
      position,
    });
  };
  /**
   * swipedCards
   *
   * @description Function to fire event to gtm to track
   * when user make swipe on current orders
   * @param {string} origin - Origin request
   */
  const swipedCards = (origin: string) => {
    trackerInstance?.track(GTMEvents.SwipedCards, {
      origin,
      userId,
    });
  };
  /**
   * historyLoaded
   *
   * @description Function to fire event to gtm to track
   * when first page is loaded
   * @param {string} origin - Origin Request
   * @param {number} currentOrders - Number of current orders
   * @param {number} orders - Number of orders without current
   */
  const historyLoaded = (origin: string, currentOrders: number, orders: number) => {
    trackerInstance?.track(GTMEvents.HistoryLoaded, {
      origin,
      userId,
      qtyLiveOrders: currentOrders,
      qtyPreOrders: currentOrders,
      qtyOrders: orders + currentOrders,
    });
  };

  const basketCouchmarkLoaded = (origin: string, basketId: string, vendorId: number) => {
    trackerInstance?.track(GTMEvents.BasketCouchmarkLoaded, {
      origin,
      userId,
      shopId: vendorId,
      cartGuid: basketId,
    });
  };

  const basketClicked = (origin: string, basketId: string, vendorId: number) => {
    trackerInstance?.track(GTMEvents.BasketClicked, {
      origin,
      userId,
      cartGuid: basketId,
      shopId: vendorId,
    });
  };

  /**
   * helpClicked
   *
   * @description Function to fire event to gtm track when user click over partner
   * @param {string} origin - Origin request
   * @param {string} origin - Origin Request
   */
  const helpClicked = (origin: string, orderId: string, orderState: string = '') => {
    trackerInstance?.track(GTMEvents.HelpClicked, {
      origin: origin,
      userId,
      orderId,
      orderState,
    });
  };

  const value = useMemo(
    () => ({
      gtmTrack: trackerInstance?.track,
      chipClicked,
      filterPageLoaded,
      applyFilter,
      swipedCards,
      repeatClicked,
      rateClicked,
      historyLoaded,
      basketCouchmarkLoaded,
      basketClicked,
      helpClicked,
      tracker: trackerInstance,
    }),
    [trackerInstance],
  );

  return <TrackerContext.Provider value={value}>{children}</TrackerContext.Provider>;
});

export { TrackerProvider };
