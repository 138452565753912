import React, { useMemo } from 'react';
import { useFWF } from '@app/shared/contexts/FWFProvider';
import { FWFFlags } from '@app/shared/config/fwfFlags';
import { useWindowSize } from '@app/shared/hooks/useWindowSize';
import { Constants } from '@app/shared/config/constants';
import { useUrlQuery } from '@app/shared/hooks/useUrlQuery';
import useIsWebView from '@app/shared/hooks/useIsWebView';

const useNavBar = () => {
  const { flags } = useFWF();
  const { width } = useWindowSize();
  const query = useUrlQuery();
  const isWebView = useIsWebView();

  return useMemo(() => {
    let showIconBar = true;
    const bottomNav = query.get(Constants.BOTTOM_NAV_QUERY_PARAM);

    if (Constants.MOBILE_LAYOUT_SIZE < width) {
      showIconBar = true;
    } else if (bottomNav !== undefined && bottomNav !== null) {
      showIconBar = bottomNav === 'true' ? false : true;
    } else if (flags[FWFFlags.showIconMenu]) {
      showIconBar = !flags[FWFFlags.showIconMenu];
    }

    return { showIconBar, isWebView };
  }, [width, flags, isWebView]);
};

export { useNavBar };
