import React from 'react';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Menu from '@pedidosya/web-fenix/icons/Menu';
import ChevronLeft from '@pedidosya/web-fenix/icons/ChevronLeft';
import { RecoveryCart } from '@app/basket/presentation/components/RecoveryCart';
import { InsideContainer, IconContainer, CartContainer } from './IOSBar.style';
import type { IOSBarProps } from './IOSBar.type';
import type { FC } from 'react';

export const IOSBar: FC<IOSBarProps> = ({ showIconBar, title, showCart, callback, isWeb }) => {
  return (
    <InsideContainer>
      {showIconBar && (
        <IconContainer>
          <span data-testid="icon_back" onClick={callback} role="button" tabIndex={0}>
            {!isWeb && <Menu size="medium" />}
            {isWeb && <ChevronLeft size="medium" />}
          </span>
        </IconContainer>
      )}
      <Typography
        token="font-body-midcontrast-sentence-large"
        component="h3"
        color="text-color-primary"
      >
        {title}
      </Typography>
      {showCart && (
        <CartContainer>
          <RecoveryCart />
        </CartContainer>
      )}
    </InsideContainer>
  );
};
