import React, { memo } from 'react';
import Translations from './OrderProductAmount.translation';
import FormatPrice from '@appscore/web-components/FormatPrice';
import reduce from 'lodash/reduce';
import { Container, AmountContainer, ProductsContainer } from './OrderProductAmount.style';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import type { DependenciesProps, OrderProductAmountProps } from './OrderProductAmount.type';
import type { FC } from 'react';
import { NumberFormatter } from '@app/shared/components/NumberFormatter';

export const OrderProductAmount: FC<OrderProductAmountProps> = WithDependencies(
  memo(({ amount = 0, products = [], dependencies, country = '' }) => {
    const { formatMessage } = (dependencies as DependenciesProps).useIntl();
    const productQuantity = reduce(
      products,
      (acc, item) => parseInt(item.quantity.toString()) + acc,
      0,
    );
    return (
      <Container>
        {amount !== null && (
          <NumberFormatter amount={amount} country={country}>
            {(amount: string) => {
              return (
                <AmountContainer
                  hasProducts={products && productQuantity}
                  token="font-body-midcontrast-sentence-small"
                  color="text-color-secondary"
                >
                  {amount}
                </AmountContainer>
              );
            }}
          </NumberFormatter>
        )}
        {products && (
          <ProductsContainer
            token="font-body-midcontrast-sentence-small"
            color="text-color-secondary"
          >
            {formatMessage(Translations['products.quantity'], { products: productQuantity })}
          </ProductsContainer>
        )}
      </Container>
    );
  }),
);
