import styled, { css } from 'styled-components';
import breakpoints from './breakpoints';
import React from 'react';

const media = {
  sm: (styles: string) => `@media only screen and (max-width: 0px) {
      ${styles}
   }`,
  md: (styles: string) => `@media only screen and (max-width:  ${breakpoints.MOBILE}px) {
      ${styles}
   }`,
  lg: (styles: string) => `@media only screen and (max-width: ${breakpoints.DESKTOP}px) {
      ${styles}
   }`,
};

interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
  flexDirection?: string;
  justify?: string;
  alignItems?: string;
  item?: boolean;
  fullHeight?: boolean;
}

export const Grid = styled.div<GridProps>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'row'};
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: ${(props) => props.alignItems || 'stretch'};
  min-height: ${(props) => (props.fullHeight ? '100vh' : 'auto')};
`;

interface ColProps extends React.HTMLAttributes<HTMLDivElement> {
  size: number;
  collapse?: keyof typeof media;
  flex?: string;
  item?: boolean;
  bordered?: boolean;
  maxHeight?: number;
}

export const Col = styled.div<ColProps>`
  flex: ${(props) => props.size};
  width: 0;
  flex-basis: inherit;
  ${(props) => props.collapse && media[props.collapse](`display: none;`)};
  ${(props) =>
    props.item &&
    css`
      flex: ${props.flex ? `${props.flex}%` : '100%'};
      width: 100%;
    `};

  ${(props) =>
    props.bordered &&
    css`
      border-right: 1px solid ${props.theme.color('text-color-action-disabled-default')};
      border-left: 1px solid ${props.theme.color('text-color-action-disabled-default')};
    `}
  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight}px;
    `}
`;

interface RowProps extends React.HTMLAttributes<HTMLDivElement> {
  fullHeight?: boolean;
  fullSize?: boolean;
  columnable?: keyof typeof media;
}
export const Row = styled.div<RowProps>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  ${(props) => props.fullHeight && `height: 100%;`};
  ${(props) => props.fullSize && `flex: 1 0;`};
  ${(props) => props.columnable && media[props.columnable](`flex-direction: column;`)};
`;
