import React from 'react';
import isFunction from 'lodash/isFunction';
import { getWindow } from '@app/shared/utils/window';

function useWebViewEvents() {
  const fire = (name: string, params: any = {}) => {
    const wn: any = getWindow();
    /**
     * Checking if webview is a device apple and have the interface
     * for send message to window
     * @param ListenEventsInterface is a interface provided by iOS developer
     */
    if (wn.webkit && wn.webkit.messageHandlers) {
      const scopes = wn.webkit.messageHandlers;
      if (
        scopes['ListenEventsInterface'] &&
        isFunction(scopes['ListenEventsInterface']?.postMessage)
      ) {
        scopes['ListenEventsInterface'].postMessage({ event: name, ...params });
      }
      /**
       * Checking if webview is a device android and have the interface
       * for send message to window
       * @param MyOrdersWebEvents is a interface provided by Android Developer
       */
    } else if (wn['MyOrdersWebEvents'] && isFunction(wn['MyOrdersWebEvents']['sendEvents'])) {
      wn['MyOrdersWebEvents']['sendEvents'](JSON.stringify({ event: name, ...params }));
    }
  };

  return {
    fire,
  };
}

export { useWebViewEvents };
