import { Constants } from '@app/shared/config/constants';
/**
 * @typdef status
 * @param {string} label - Key from translate file
 */
/**
 * getStatusOrder
 *
 * @description Function to return status config
 * @example getStatusConf('PENDING'); return {'label': ''}
 * @return {status}
 */
export const getStatusOrder = (status: string) => {
  if (status === Constants.IN_PROGRESS_STATE) {
    return {
      label: 'card.state.pending',
      color: 'pending',
    };
  }
  if (status === Constants.DELIVERED_STATE) {
    return {
      label: 'card.state.delivered',
      color: 'delivered',
    };
  }
  if (status === Constants.CANCELLED_STATE) {
    return {
      label: 'card.state.cancelled',
      color: 'cancelled',
    };
  }

  return {};
};
