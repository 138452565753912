import { DEVICESOS, DEVICES } from '@app/shared/config/device';
import { Routes } from '@app/shared/config/routes';

export const DeepLink = {
  repeatOrder: {
    ios: 'pedidosya://repeatOrder?orderId={orderId}&partnerId={partnerId}',
    android: 'pedidosya://myOrders/{orderId}',
    web: '/my-orders/repeat/order/{orderId}',
  },
  home: {
    ios: 'pedidosya://launcher',
    android: 'pedidosya://launcher',
    web: '/',
  },
  orderStatus: {
    ios: 'pedidosya://os/main?orderId={id}&origin=myOrders',
    android: 'pedidosya://orderStatus?orderId={id}&origin=myOrders',
    web: '/order-state?orderId={id}&c={country}&origin=myOrders',
  },
  feedback: {
    ios: 'pedidosya://feedback?survey={surveyId}&resource={resourceId}&origin=myOrders',
    android: 'pedidosya://feedback?survey={surveyId}&resource={resourceId}&origin=myOrders',
    web: '/feedback/web?survey={surveyId}&resource={resourceId}&origin=myOrders&device=web&language=es',
  },
  otherOrder: {
    ios: 'pedidosya://restaurantes?bt={vertical}',
    android: 'pedidosya://restaurantes?bt={vertical}',
    web: '/restaurantes/{city}/{name}-menu',
  },
  detail: {
    ios: 'pedidosya://os/orderDetails?orderId={orderId}&origin=myOrders&hideNavigation=true',
    android: 'pedidosya://os/orderDetails?orderId={orderId}&origin=myOrders&hideNavigation=true',
    web: '/order-details?orderId={orderId}&origin=myOrders',
  },
  feedbackWeb: {
    ios: `/feedback/web?resource={resourceId}&survey={surveyId}&device=web&language=es&c={country}&origin=myOrders&callback=${Routes.MY_ORDERS}%3FhasBottomNav%3D{hasBottomNav}`,
    android: `/feedback/web?resource={resourceId}&survey={surveyId}&device=web&language=es&c={country}&origin=myOrders&callback=${Routes.MY_ORDERS}%3FhasBottomNav%3D{hasBottomNav}`,
    web: `/feedback/web?resource={resourceId}&survey={surveyId}&device=web&language=es&c={country}&origin=myOrders&callback=${Routes.MY_ORDERS}`,
  },
  basketRecreation: {
    ios: 'pedidosya://basketRecreation?referenceId={resourceId}&referenceType=ORDER&flow=myOrders&partnerId={partnerId}&country={country}',
    android:
      'pedidosya://basketRecreation?referenceId={resourceId}&referenceType=ORDER&flow=myOrders&partnerId={partnerId}&country={country}',
    web: '/basket-recreation/{resourceId}/orders?flow=myOrders',
  },
  basketHubNew: {
    ios: 'pedidosya://basket/hub?origin=mis_pedidos',
    android: 'pedidosya://basket/hub?origin=mis_pedidos',
    web: '/',
  },
  newFeedback: {
    ios: '/my-orders/feedback/{country}/{resourceId}/{surveyId}',
    android: '/my-orders/feedback/{country}/{resourceId}/{surveyId}',
    web: '/my-orders/feedback/{country}/{resourceId}/{surveyId}',
  },
  helpCenter: {
    ios: 'pedidosya://onlinehelp?order_id={id}',
    android: 'pedidosya://onlinehelp?order_id={id}',
    web: '/customer-service?orderId={id}',
  },
};

export const ACTIONS = {
  REPEAT_ORDER: 'repeatOrder',
  HOME: 'home',
  ORDER_STATUS: 'orderStatus',
  FEEDBACK: 'feedback',
  MAKE_ORDER: 'otherOrder',
  DETAIL: 'detail',
  FEEDBACK_WEB: 'feedbackWeb',
  BASKET_RECREATION: 'basketRecreation',
  BASKETHUBNEW: 'basketHubNew',
  NEW_FEEDBACK: 'newFeedback',
  HELP_CENTER: 'helpCenter',
};

/**
 * generateDeepLink
 *
 * @description Function for generate deeplink based on os param
 * @param {string} action - Action to generate deep link
 * @param {string} os - OS of device (Ios | AndroidOS)
 * @param {Object} params - Any param to replace in final deeplink
 * @returns {string}
 */
export const generateDeepLink = (action, os, params) => {
  switch (os) {
    case DEVICESOS.ANDROID:
      return replaceAll(DeepLink[action].android, params);
    case DEVICESOS.IOS:
      return replaceAll(DeepLink[action].ios, params);
    case DEVICESOS.WEB:
      return replaceAll(DeepLink[action].web, params);
    default:
      return '';
  }

  function replaceAll(str, params) {
    let finalStr = str;
    for (const [key, value] of Object.entries(params)) {
      finalStr = finalStr.replace(`{${key}}`, value);
    }
    return finalStr;
  }
};

/**
 * generateLink
 *
 * @description Function for genereate link or deeplink in base to platform
 * @param {string} device - Name of device
 * @param {string} deviceOS - Operative System of device
 * @param {Object} params - Object with parameters to build final urls
 */
export const generateLink = (device, deviceOS, action, params) => {
  if (DEVICES.MOBILE === device || DEVICES.WEBVIEW === device) {
    return generateDeepLink(action, deviceOS, params);
  } else {
    return generateDeepLink(action, DEVICESOS.WEB, params);
  }
};
